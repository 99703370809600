const setAdvertisementList = (data) => ({
  type: "SET_Advertisement_LIST",
  data
});

const setAdvertisementPage = (data) => ({
  type: "SET_Advertisement_PAGE",
  data
});

export const mutation = {
  setAdvertisementList,
  setAdvertisementPage
};
