import { initialState } from "./state";

export const Advertisement = (state = initialState, action) => {
  switch (action.type) {
    case "SET_Advertisement_LIST": {
      return Object.assign({}, state, {
        AdvertisementList: action.data
      });
    }
    case "SET_Advertisement_PAGE": {
      return Object.assign({}, state, {
        AdvertisementPage: action.data
      });
    }
    default:
      return state;
  }
};
