import styled from "styled-components";
import { Theme, Size, FontFamily } from "static/coreStyles/theme.styled";

export const SearchContainer = styled.div`
  padding: 30px 40px 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: ${Size.HeaderHeight};
  z-index: 5;
  .search-icon {
    visibility: ${(props) => (props.showSearchBar ? "visible" : "hidden")};
    position: absolute;
    top: 45px;
    left: 56px;
  }
  position: fixed;
  width: calc(100% - ${Size.NavbarWidth});
  background: ${Theme.secondaryColor};
  top: 0;
  .fade {
    animation: fadeIn 0.3s linear;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      ,
      100% {
        opacity: 1;
      }
    }
  }
  .loader {
    position: absolute;
    top: 45px;
    left: 330px;
    padding: 2px;
    height: 15px;
    width: 15px;
  }
`;
export const UserDropdownContent = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  background-color: hsl(0, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  z-index: 1;
  border: solid 1px white;
  .option:hover {
    color: #0b5fe3;
  }
  .option {
    color: #626d79;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 12px;
    width: 100%;
    font-family: ${FontFamily.SemiBold};
    font-weight: 600;
  }
`;
export const UserDropdown = styled.div`
  background: ${Theme.white};
  display: flex;
  cursor: pointer;
  justify-content: ${(props) =>
    props.profile ? "flex-start" : "space-between"};
  padding: 8px;
  align-items: center;
  font-family: ${FontFamily.SemiBold};
  font-size: 13px;
  font-weight: 600;
  border-radius: 6px;
  min-width: 120px;
  height: 50px;
  color: #626d79;
  .profile-pic {
    width: 31px;
    height: 31px;
    margin-right: 8px;
  }
  .profile-name {
    margin-right: 18px;
  }
`;

export const SearchInput = styled.input`
  visibility: ${(props) => (props.showSearchBar ? "visible" : "hidden")};
  width: 320px;
  height: 45px;
  border-radius: 6px;
  background-color: ${Theme.white};
  border: unset;
  color: #252525;
  font-family: ${FontFamily.Medium};
  padding: 10px 40px 10px 40px;
  cursor: text;
  ::placeholder {
    opacity: 0.4;
    color: #252525;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.base};
    font-weight: 500;
    line-height: 1.63;
  }
`;

export const customStyles = {
  control: (base) => ({
    ...base,
    width: "120px",
    height: "50px",
    borderRadius: "6px",
    border: "solid 1px white",
    padding: "0  0 0 10px",
    boxShadow: "none",
    "&:hover": {
      cursor: "text",
      border: "solid 1px white"
    }
  }),
  placeholder: (base) => ({
    ...base,
    opacity: "0.2",
    fontFamily: FontFamily.SemiBold,
    fontSize: Size.base,
    fontWeight: "600",
    color: "#212121"
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none"
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    cursor: "pointer"
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#626d79"
  }),
  option: (base) => ({
    ...base,
    cursor: "pointer",
    fontFamily: FontFamily.SemiBold,
    fontSize: "13px",
    fontWeight: "600",
    color: "#626d79",
    backgroundColor: Theme.white,
    "&:hover": {
      color: Theme.primaryColor
    },
    ":active": {
      ...base[":active"],
      backgroundColor: Theme.white
    }
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "127px"
  }),
  menu: (base) => ({
    ...base,
    borderRadius: "6px",
    border: "solid 1px white",
    boxShadow: "0 2px 14px 0 rgba(0, 0, 0, 0.1)"
  }),
  singleValue: (base) => ({
    ...base,
    fontFamily: FontFamily.SemiBold,
    fontSize: "13px",
    fontWeight: "600",
    color: "#626d79"
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0"
  })
};
