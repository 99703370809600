import React from "react";
import { UploadTitle, ErrorMessage } from "static/coreStyles/global.styled";
import Select from "react-select";
import {
  DropdownContainer,
  customStyles
} from "./DropdownInputContainer.styled";
import { Flexbox } from "static/coreStyles/container.styled";

function DropdownInputContainer(props) {
  let imgOptions = [];
  if (props.img) {
    imgOptions =
      props.options && props.options.length
        ? props.options.map((option, index) => {
            return {
              id: option.id ? option.id : option[props.value],
              value: option.name,
              label: (
                <Flexbox alignItems="center">
                  <img
                    src={option.url}
                    height={props.height ? props.height : 25}
                    width={props.width ? props.width : 25}
                    style={{ marginRight: "7px" }}
                    alt={option.name}
                  />
                  {option.name}
                </Flexbox>
              )
            };
          })
        : [];
  }

  let defaultValue = props.defaultValue;
  if (props.edit && !props.multi && !props.noAlter) {
    defaultValue = props.options
      ? props.options.find((option) => {
          return (
            option[props.value ? props.value : "value"] === props.defaultValue
          );
        })
      : {};
    defaultValue =
      defaultValue && props.img
        ? {
            label: (
              <Flexbox alignItems="center">
                <img
                  src={defaultValue.url}
                  height={props.height ? props.height : 25}
                  width={props.width ? props.width : 25}
                  style={{ marginRight: "7px" }}
                  alt={defaultValue.name}
                />
                {defaultValue.name}
              </Flexbox>
            ),
            value: props.defaultValue
          }
        : defaultValue;
  }
  return (
    <DropdownContainer width={props.width} invalid={props.invalid}>
      <Flexbox>
        <UploadTitle>{props.title}</UploadTitle>
        {props.invalid && !props.modal && (
          <ErrorMessage>
            {props.group ? `Minimum 2 Required*` : `Required*`}
          </ErrorMessage>
        )}
      </Flexbox>
      {props.multi ? (
        <Select
          defaultValue={
            props.edit || props.default ? props.defaultValue : undefined
          }
          value={props.values}
          styles={customStyles}
          isClearable={props.clearable}
          isMulti
          options={props.options}
          placeholder={props.placeholder}
          onChange={props.passSelectedValues}
          isDisabled={props.disabled}
          classNamePrefix="dropdown"
        />
      ) : props.img ? (
        <Select
          styles={customStyles}
          options={imgOptions}
          placeholder={props.placeholder}
          value={props.edit && defaultValue}
          onChange={props.passSelectedValues}
          isDisabled={props.disabled}
          defaultValue={props.edit || props.default ? defaultValue : undefined}
          classNamePrefix="dropdown"
        />
      ) : (
        <Select
          styles={customStyles}
          options={props.img ? imgOptions : props.options}
          placeholder={props.placeholder}
          onChange={props.passSelectedValues}
          isDisabled={props.disabled}
          defaultValue={props.edit || props.default ? defaultValue : undefined}
          classNamePrefix="dropdown"
          isClearable={props.clearable}
          value={props.value}
        />
      )}
    </DropdownContainer>
  );
}
export default DropdownInputContainer;
