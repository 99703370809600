import React from "react";
import { Flexbox } from "static/coreStyles/container.styled";

function PaginationLoader(props) {
  return (
    <Flexbox justifyContent="center">
      <img
        src={require("static/images/pagination-loader.svg")}
        alt="Loader"
        height="60px"
      />
    </Flexbox>
  );
}
export default PaginationLoader;
