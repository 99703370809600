import styled from "styled-components";
import { Theme, Size, FontFamily } from "static/coreStyles/theme.styled";

export const Container = styled.div`
  z-index: 2;
  background-color: ${Theme.primaryColor};
  position: fixed;
  top: 0;
  left: 0;
  width: ${Size.NavbarWidth};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0 0;
`;
export const NavContainer = styled.div`
  .icons {
    width: 22px;
    height: 22px;
    opacity: ${(props) => (props.active ? "1" : "0.3")};
  }
  .messenger-pro-icon {
    padding-bottom: 70px;
  }
  display: flex;
  align-items: center;
  padding: 14px 20px 14px 30px;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  .heading {
    font-family: ${FontFamily.ExtraBoldItalic};
    font-size: 15px;
    line-height: 1.3;
    letter-spacing: 0.08px;
    color: ${Theme.white};
    padding-bottom: 70px;
    padding-left: 10px;
  }
  .title {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.base};
    color: ${Theme.white};
    opacity: ${(props) => (props.active ? "1" : "0.3")};
    text-transform: capitalize;
    padding-left: 10px;
  }
  .border {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    width: 4px;
    height: 100%;
    position: absolute;
    background: ${(props) => (props.active ? "white" : "transparent")};
    top: 0;
    right: 0;
  }
`;
