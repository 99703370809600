import React, { useState } from "react";
import { InputField, TextField } from "./InputContainer.styled";
import { UploadTitle, ErrorMessage } from "static/coreStyles/global.styled";
import { Flexbox } from "static/coreStyles/container.styled";

function InputContainer(props) {
  const [inputValue, setInputValue] = useState(props.value ? props.value : "");
  function handleInputChange(value) {
    props.getInput(value);
    setInputValue(value);
  }
  return (
    <Flexbox flexDirection="column" marginBottom="20px">
      {props.title && (
        <Flexbox>
          <UploadTitle>{props.title}</UploadTitle>
          {props.invalid && <ErrorMessage>Required*</ErrorMessage>}
        </Flexbox>
      )}
      {props.textbox ? (
        <TextField
          placeholder={props.placeholder}
          value={inputValue}
          onChange={(e) => {
            handleInputChange(e.target.value);
          }}
          invalid={props.invalid}
        />
      ) : (
        <InputField
          placeholder={props.placeholder}
          width={props.width}
          padding={props.padding}
          value={
            props.color
              ? inputValue && !inputValue.includes("#")
                ? `#${inputValue}`
                : undefined
              : inputValue
          }
          onChange={(e) => {
            handleInputChange(e.target.value);
          }}
          type={props.color ? "color" : "text"}
          invalid={props.invalid}
        />
      )}
    </Flexbox>
  );
}
export default InputContainer;
