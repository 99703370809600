import { User } from "services/User";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";
import { store } from "store";

const handleError = (err) => {
  console.log("Error in User action : ", err);
};

// Actions
export const getUserListRequest = (page) => async (dispatch) => {
  try {
    let prevList = page && store.getState().User.UserList;

    page = page ? page.split("api/v1/")[1] : false;
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await User.getUserList(country, page);
    if (data.result) {
      dispatch(
        mutation.setUserPage({
          count: data.count,
          totalCount: data.totalCount,
          next: data.next,
          prev: data.prev
        })
      );
      dispatch(
        mutation.setUserList(
          prevList ? prevList.concat(data.result) : data.result
        )
      );
    }
    return data.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const createUserRequest = (payload) => async (dispatch) => {
  try {
    return await User.createUser(payload);
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateUserByIdRequest = (id, payload) => async (dispatch) => {
  try {
    let result = await User.updateUserById(id, payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const deleteUserByIdRequest = (id) => async (dispatch) => {
  try {
    return await User.deleteUserById(id);
  } catch (err) {
    handleError(err);
  }
};

export const updateUserBlockStatusRequest = (id, block) => async (dispatch) => {
  try {
    return await User.updateUserBlockStatus(id, block);
  } catch (err) {
    handleError(err);
  }
};

export const exportUsersRequest = () => async (dispatch) => {
  try {
    return await User.exportUsers();
  } catch (err) {
    handleError(err);
  }
};
