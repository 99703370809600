import React from "react";
import { SwitchContainer } from "./Switch.styled";

function Switch(props) {
  return (
    <SwitchContainer onClick={e => e.stopPropagation()}>
      <label className="switch">
        <input
          type="checkbox"
          checked={props.state}
          onChange={() => {
            props.changeStatus();
          }}
        />
        <span className="slider round"></span>
      </label>
    </SwitchContainer>
  );
}
export default Switch;
