import React, { useState } from "react";
import Drawer from "components/common/Drawer";
import { DrawerBody } from "static/coreStyles/global.styled";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import { getScope } from "utility/helperFunctions";
import swal from "sweetalert";

function TopApplicationsDrawer(props) {
  const [selectedApps, setSelectedApps] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const [loader, setLoader] = useState(false);

  const applications = Object.keys(
    props.CategoryDetail && props.CategoryDetail.allApps
  ).map((option) => {
    return { value: option, label: props.CategoryDetail.allApps[option].name };
  });

  let defaultApps =
    props.CategoryDetail &&
    props.CategoryDetail.topApps &&
    props.CategoryDetail.topApps.map((app) => {
      return { value: app, label: props.CategoryDetail.allApps[app].name };
    });

  function validateForm() {
    if (
      (selectedApps &&
        selectedApps.length < 1 &&
        defaultApps &&
        defaultApps.length < 1) ||
      !selectedApps
    ) {
      setInvalid(true);
      return false;
    }
    return true;
  }

  function submitForm() {
    if (validateForm()) {
      setLoader(true);
      let payload = {
        scope: getScope(true),
        sectionType: 1,
        apps:
          selectedApps && selectedApps.length
            ? selectedApps.map((app) => app.value)
            : defaultApps
            ? defaultApps.map((app) => app.value)
            : []
      };
      props
        .updateCategoryDetailRequest(props.categoryId, payload)
        .then((res) => {
          if (res.error) {
            setLoader(false);
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else {
            setLoader(false);
            props.getCategoryDetailRequest(props.categoryId);
            props.getCategoryListRequest();
            props.closeDrawer();
          }
        });
    }
  }
  return (
    <Drawer
      title="Top 10 Applications"
      width="500px"
      show={props.show}
      closeDrawer={props.closeDrawer}
      footerText="Save"
      loader={loader}
      onSave={submitForm}
    >
      <DrawerBody>
        <DropdownInputContainer
          title="Select Top 10 Applications"
          defaultValue={defaultApps}
          options={applications}
          passSelectedValues={(values) => {
            defaultApps = [];
            setSelectedApps(values);
          }}
          multi
          edit
          invalid={invalid && !selectedApps}
        />
      </DrawerBody>
    </Drawer>
  );
}
export default connect(
  ({ Category }) => ({ ...Category }),
  (dispatch) => bindActionCreators({ ...categoryActions }, dispatch)
)(TopApplicationsDrawer);
