import { Category } from "services/Category";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";
import { store } from "store";

const handleError = (err) => {
  console.log("Error in Category action : ", err);
};

// Actions
export const getCategoryListRequest = (page) => async (dispatch) => {
  try {
    let prevList = page && store.getState().Category.CategoryList;
    page = page ? page.split("api/v1/")[1] : false;
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await Category.getCategoryList(country, page);
    if (data.result) {
      dispatch(
        mutation.setCategoryPage({
          count: data.count,
          totalCount: data.totalCount,
          next: data.next,
          prev: data.prev
        })
      );
      dispatch(
        mutation.setCategoryList(
          prevList ? prevList.concat(data.result) : data.result
        )
      );
    }

    return data.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getCategoryDetailRequest = (id) => async (dispatch) => {
  try {
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await Category.getCategoryDetail(id, country);
    if (data.result) {
      let allApps = data.dictionary.apps;
      data.result.allApps = allApps;

      //creating array of banners
      let appArray = allApps ? Object.entries(allApps) : [];
      let appArraySize = appArray.length;
      let i, j, size, banners;
      let bannerOptions = {};
      for (i = 0; i < appArraySize; i++) {
        banners = appArray[i][1].images.banners;
        size = banners.length;
        for (j = 0; j < size; j++) {
          banners[j].appId = appArray[i][0];
          bannerOptions[banners[j].id] = banners[j];
        }
      }
      data.result.bannerOptions = bannerOptions;

      dispatch(mutation.setCategoryDetail(data.result));
    }
    return data.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const createCategoryRequest = (payload) => async (dispatch) => {
  try {
    let data = await Category.createCategory(payload);
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateCategoryByIdRequest = (id, payload) => async (dispatch) => {
  try {
    let data = await Category.updateCategoryById(id, payload);
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateCategoryDetailRequest = (id, payload) => async (
  dispatch
) => {
  try {
    let data = await Category.updateCategoryDetail(id, payload);
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const changeCategoryActiveStatusRequest = (id, payload) => async (
  dispatch
) => {
  try {
    return await Category.changeCategoryActiveStatus(id, payload);
  } catch (err) {
    handleError(err);
  }
};
