import styled from "styled-components";
import { Theme } from "static/coreStyles/theme.styled";
const loaderSize = "4rem";
const AnimationDuration = "1.5s";
export const LoaderContainer = styled.div`
  width: 100%;
  min-height: ${props =>
    props.minHeight ? props.minHeight : "calc(100vh - 220px)"};
  display: flex;
  justify-content: center;
  align-items: center;
  .rectangle-bounce {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: ${loaderSize};
    height: ${loaderSize};
    transition: all 300ms ease-in-out 0s;
    z-index: 1;

    .rect-1,
    .rect-2,
    .rect-3,
    .rect-4,
    .rect-5 {
      border-radius: 25px;
      width: 10%;
      height: 100%;
      background-color: ${Theme.primaryColor};
      animation: rectangle-bounce ${AnimationDuration} infinite ease-in-out;
    }

    .rect-2 {
      animation-delay: -1.6s;
    }
    .rect-3 {
      animation-delay: -1.7s;
    }
    .rect-4 {
      animation-delay: -1.8s;
    }
    .rect-5 {
      animation-delay: -1.9s;
    }
  }

  @keyframes rectangle-bounce {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
    }
  }
`;
