import { initialState } from "./state";

export const Explore = (state = initialState, action) => {
  switch (action.type) {
    case "SET_Explore_LIST": {
      return Object.assign({}, state, {
        ExploreList: action.data
      });
    }
    case "SET_EXPLORE_APPS_LIST": {
      return Object.assign({}, state, {
        ExploreAppsList: action.data
      });
    }
    default:
      return state;
  }
};
