import React, { useState } from "react";
import Modal from "components/common/Modal";
import { Flexbox } from "static/coreStyles/container.styled";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { ModalBody, ModalFooter, Banner } from "./UploadImageModal.styled";
import { connect } from "react-redux";

function UploadImageModal(props) {
  const [NewApplication, setNewApplication] = useState(null);
  const [NewBanner, setNewBanner] = useState("");
  const [BannerList, setBannerList] = useState(
    props.detail.banners.length
      ? props.detail.banners.map((banner) => {
          return {
            label: props.detail.allApps[banner.appId].name,
            value: banner.appId,
            app: props.detail.allApps[banner.appId]
          };
        })
      : []
  );
  const [selected, setSelected] = useState(
    props.detail.banners.length
      ? props.detail.banners.map((banner) => banner.bannerId)
      : []
  );
  const [invalid, setInvalid] = useState(false);
  function save() {
    props.getImages(
      selected.length > 0 &&
        selected.map((id) => {
          return props.bannerOptions[id];
        })
    );
    props.closeModal();
  }

  let applicationOptions = Object.keys(props.detail.allApps).map((appId) => {
    return {
      label: props.detail.allApps[appId].name,
      value: appId,
      app: props.detail.allApps[appId]
    };
  });

  function addBanner() {
    if (NewApplication && NewApplication.label && NewBanner) {
      setBannerList([...BannerList, NewApplication]);
      setNewApplication(null);
    }
  }
  function updateBanner(value, index) {
    let BannerArray = [...BannerList];
    BannerArray[index] = value;
    setBannerList(BannerArray);
    setSelected([
      ...selected.slice(0, index),
      ...selected.slice(index + 1, selected.length)
    ]);
  }
  function removeBanner(i) {
    setBannerList([
      ...BannerList.slice(0, i),
      ...BannerList.slice(i + 1, BannerList.length)
    ]);
    setSelected([
      ...selected.slice(0, i),
      ...selected.slice(i + 1, selected.length)
    ]);
  }

  function selectBanner(index, id) {
    setInvalid(false);
    if (index > -1) {
      let selectedBanner = [...selected];
      selectedBanner[index] = id;
      setSelected(selectedBanner);
    } else {
      let selectedBanner = [...selected];
      selectedBanner[BannerList.length] = id;
      setSelected(selectedBanner);
    }
    setNewBanner(id);
  }

  function getNewApplication(val) {
    setNewApplication(val);
    setInvalid(false);
  }

  function validateForm() {
    if (selected && selected.length) {
      setInvalid(false);
      save();
    } else {
      setInvalid(true);
    }
  }

  return (
    <Modal
      show={props.show}
      title="Choose Banner Images"
      closeModal={props.closeModal}
      banner
    >
      <ModalBody>
        {BannerList &&
          BannerList.length > 0 &&
          BannerList.map((value, index) => {
            return (
              <Flexbox
                width="100%"
                marginTop="5px"
                flexDirection="column"
                key={index}
              >
                <Flexbox>
                  <DropdownInputContainer
                    placeholder={"Select Application"}
                    width="calc(100% - 70px)"
                    height="70px"
                    options={applicationOptions.filter((app) =>
                      BannerList.length
                        ? !BannerList.some(
                            (banner) => banner.value === app.value
                          )
                        : app
                    )}
                    passSelectedValues={(val) => updateBanner(val, index)}
                    defaultValue={{ label: value.label, value: value.value }}
                    edit
                    noAlter
                  />
                  <div
                    className="button-holder minus pointer"
                    onClick={() => removeBanner(index)}
                  >
                    <img
                      alt="subtract"
                      src={require("static/images/minus.svg")}
                    />
                  </div>
                </Flexbox>

                <Flexbox>
                  {value.app.images.banners.length > 0
                    ? value.app.images.banners.map((banner) => {
                        return (
                          <Banner
                            onClick={() => {
                              selectBanner(index, banner.id);
                            }}
                            isSelected={selected[index] === banner.id}
                            key={banner.id}
                            invalid={invalid && !selected.includes(banner.id)}
                          >
                            <img src={banner.mediaUrl} alt={banner.id} />
                          </Banner>
                        );
                      })
                    : null}
                </Flexbox>
              </Flexbox>
            );
          })}
        <Flexbox width="100%" marginTop="5px" flexDirection="column">
          <Flexbox>
            <DropdownInputContainer
              placeholder={"Select Application"}
              width="calc(100% - 70px)"
              height="70px"
              options={applicationOptions.filter((app) =>
                BannerList.length
                  ? !BannerList.some((banner) => banner.value === app.value)
                  : app
              )}
              passSelectedValues={(val) => getNewApplication(val)}
              modal
              value={NewApplication}
              invalid={invalid && !NewApplication && !NewBanner}
            />
            <div
              className="button-holder plus pointer"
              onClick={() => addBanner()}
            >
              <img alt="add" src={require("static/images/add-circle.svg")} />
            </div>
          </Flexbox>

          <Flexbox>
            {NewApplication && NewApplication.value
              ? NewApplication.app.images.banners.map((banner) => {
                  return (
                    <Banner
                      onClick={() => {
                        selectBanner(-1, banner.id);
                      }}
                      isSelected={selected.includes(banner.id)}
                      key={banner.id}
                      invalid={invalid && !selected.includes(banner.id)}
                    >
                      <img src={banner.mediaUrl} alt={banner.id} />
                    </Banner>
                  );
                })
              : null}
          </Flexbox>
        </Flexbox>
      </ModalBody>
      <ModalFooter>
        <button className="apply" onClick={validateForm}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default connect(({ Application }) => ({
  ...Application
}))(UploadImageModal);
