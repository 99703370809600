import styled from "styled-components";
import { Theme, FontFamily, Size } from "static/coreStyles/theme.styled";

export const SectionsRowContainer = styled.div`
  border-radius: 6px;
  background-color: ${Theme.white};
  padding: 22px 0 22px 20px;
  margin-bottom: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  :first-child {
    margin-top: 12px;
  }
  :last-child {
    margin-bottom: 0;
  }
  .reorder {
    display: flex;
    flex-wrap: wrap;
  }
  .top-row {
    display: flex;
  }
  .app-name {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.xs};
    font-weight: 500;
    color: #636970;
    margin-top: 9px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
  .app-edge {
    color: ${Theme.primaryColor};
  }

  .add-new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background-color: rgba(160, 181, 205, 0.2);
    border-radius: 8px;
  }
  .add-new-circle {
    width: 13px;
    height: 13px;
  }
  .application {
    object-fit: contain;
    height: 38px;
    border-radius: 6px;
    width: 38px;
  }
  .ad-icon {
    position: absolute;
    top: -8px;
    left: 25px;
    width: 22px;
    height: 17px;
    border-radius: 6px;
    padding-top: 2px;
    border: solid 0.9px ${Theme.white};
    background-color: #52caf7;
    font-family: ${FontFamily.SemiBold};
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.03px;
    text-align: center;
    color: #08416f;
  }
`;

export const AppContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 6px;
  padding: 10px 0;
  position: relative;
  :last-child {
    margin-right: 0;
  }
  .edit-section {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -13px;
    right: -10px;
    display: none;
  }

  :hover {
    cursor: pointer;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.07);
    .edit-section {
      display: block;
    }
  }
`;
