import React from "react";
import { AddAdsContainer } from "./AdPool.styled";
import { connect } from "react-redux";

function AdPool(props) {
  const size = 3;

  return (
    <AddAdsContainer>
      <img
        alt="star"
        src={require("static/images/star.svg")}
        className="ad-star"
      />
      <div className="icon-container">
        {props.ids &&
          props.ids.length > 0 &&
          props.ids.slice(0, size).map((ad, index) => {
            ad = props.Section.advs[ad];
            return (
              <img
                alt="ad"
                src={ad && ad.app && ad.app.icon && ad.app.icon.mediaUrl}
                className="app-icon"
                key={index}
              />
            );
          })}
      </div>
      <div className="app-name">Ad</div>
    </AddAdsContainer>
  );
}
export default connect(({ Section }) => ({ ...Section }))(AdPool);
