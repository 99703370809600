import React from "react";
import { Empty } from "./Dashboard.styled";
import { Flexbox } from "static/coreStyles/container.styled";

function Dashboard(props) {
  return (
    <Flexbox
      justifyContent="center"
      alignItems="center"
      height="-webkit-fill-available" /* Mozilla-based browsers will ignore this. */
      flexDirection="column"
    >
      <img src={require("static/images/empty-dashboard.svg")} alt="dashboard" />
      <Empty>COMING SOON..</Empty>
    </Flexbox>
  );
}
export default Dashboard;
