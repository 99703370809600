import styled from "styled-components";
import { Size, FontFamily } from "static/coreStyles/theme.styled";

export const UserListingHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 16px;
  border-radius: 6px;
  background-color: rgba(160, 181, 205, 0.2);
  margin-top: 12px;
  font-family: ${FontFamily.Medium};
  font-size: ${Size.sm};
  font-weight: 500;
  color: rgba(99, 105, 112, 0.7);
  div {
    margin-right: 20px;
    :last-child {
      margin-right: 0;
    }
  }
`;
