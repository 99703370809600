import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { Category } from "./Category/reducer";
import { User } from "./User/reducer";
import { Login } from "./Login/reducer";
import { FileUpload } from "./FileUpload/reducer";
import { Application } from "./Application/reducer";
import { Country } from "./Country/reducer";
import { Explore } from "./Explore/reducer";
import { Advertisement } from "./Advertisement/reducer";
import { Search } from "./Search/reducer";
import { Section } from "./Section/reducer";

export const rootReducer = {
  Category,
  User,
  Login,
  FileUpload,
  Application,
  Country,
  Explore,
  Advertisement,
  Search,
  Section
};
const devTool = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : compose;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["Country"]
};

const persistCombinedReducers = persistCombineReducers(
  persistConfig,
  rootReducer
);

export const store = createStore(
  persistCombinedReducers,
  compose(applyMiddleware(thunkMiddleware), devTool)
);

export const persistor = persistStore(store);
