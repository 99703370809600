import axios from "axios";
import { API_URL } from "./apiUrl";
import { storage as LocalStorage } from "../../services/config/storage";
import swal from "sweetalert";

const _authorizationHeaders = () => ({
  Authorization: LocalStorage.fetch.authToken()
    ? LocalStorage.fetch.authToken()
    : "",
  "Content-Type": "application/json"
});

axios.interceptors.response.use(
  function(response) {
    if (response === undefined) {
      swal({
        title: "Oops!",
        text: "Some error occured, kindly refresh",
        icon: "error"
      });
      return {};
    }
    return response;
  },
  function(error) {
    console.log(error);
    if (error.statusCode === 401) {
      logout();
    }
    if (error.toJSON().message === "Network Error") {
      swal({
        title: "Oops!",
        text: "Your Internet Connection seems broken, Try Reloading",
        icon: "error"
      });
      return {};
    }
    return error;
  }
);

const logout = () => {
  alert("logout");
  LocalStorage.destroy.authToken();
  LocalStorage.destroy.userData();
};

export const getRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers)
    });
    return res.response ? res.response.data : res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data
    });
    return res.response ? res.response.data : res.data;
  } catch (err) {
    return err.response && err.response.data;
  }
};

export const putRequest = async (
  url,
  data = {},
  contentType,
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data
    });
    return res.response ? res.response.data : res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const patchRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const Api = {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  patchRequest
};
