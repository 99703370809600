import React, { useState } from "react";
import Drawer from "components/common/Drawer";
import { DrawerBody } from "static/coreStyles/global.styled";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as exploreActions from "store/Explore/actions";
import { getScope } from "utility/helperFunctions";
import swal from "sweetalert";

function ExploreDrawer(props) {
  const [category, setCategory] = useState("");
  const [appsList, setAppslist] = useState([]);
  const [topApps, setTopApps] = useState();
  const [submitType, setSubmitType] = useState();
  const [invalid, setInvalid] = useState(false);

  const categories =
    props.CategoryList &&
    props.CategoryList.reduce(function (filtered, option) {
      if (option.isActive) {
        filtered.push({ label: option.name, value: option.id });
      }
      return filtered;
    }, []);
  const applications =
    props.ApplicationList &&
    Object.values(props.ApplicationList).reduce(function (filtered, option) {
      if (option.isActive) {
        filtered.push({ label: option.name, value: option.id });
      }
      return filtered;
    }, []);
  let defaultApplications =
    props.apps &&
    props.apps.length > 0 &&
    props.apps.map((app) => {
      return {
        value: app.id,
        label: app.name
      };
    });

  function getTopApps(value) {
    setInvalid(false);
    let category =
      props.CategoryList &&
      props.CategoryList.find((category) => category.id === value.value);
    setTopApps(
      category.topApps.map((app) => {
        return {
          value: app.id,
          label: app.name
        };
      })
    );
    setCategory(value.value);
    setSubmitType("category");
  }

  function getAppsList(value) {
    defaultApplications = [];
    setInvalid(false);
    setAppslist(value);
    setSubmitType("apps");
  }

  function formValidation() {
    if (props.category) {
      if (!category && !props.categoryId) {
        setInvalid(true);
        return false;
      }
    } else {
      if (
        (appsList && appsList.length < 1 && !defaultApplications) ||
        !appsList
      ) {
        setInvalid(true);
        return false;
      }
    }
    return true;
  }

  function submitForm() {
    if (formValidation()) {
      if (submitType === "apps" || defaultApplications) {
        let appIds = appsList.length
          ? appsList.map((app) => {
              return app.value;
            })
          : defaultApplications.length
          ? defaultApplications.map((app) => {
              return app.value;
            })
          : [];
        let payload = {
          scope: getScope(true)
        };

        switch (props.rowName) {
          case "Featured Apps":
            payload.featuredApps = appIds;
            break;
          case "Try something new":
            payload.tryNew = appIds;
            break;
          case "People are Loving":
            payload.peopleAreLoving = appIds;
            break;
          default:
            break;
        }

        props.updateExploreRequest(payload).then((res) => {
          if (res.error) {
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else {
            props
              .getExploreListRequest()
              .then((res) => res && props.closeDrawer());
          }
        });
      }
      if (submitType === "category" || props.categoryId) {
        let topCategoryIds =
          props.ExploreList.topCategories &&
          props.ExploreList.topCategories.map((index) => {
            return index.id;
          });
        topCategoryIds[props.index] = category ? category : props.categoryId;
        props
          .updateExploreRequest({
            scope: getScope(true),
            topCategories: topCategoryIds
          })
          .then((res) => {
            if (res.error) {
              swal({
                title: "Oops!",
                text: res.error.message,
                icon: "error"
              });
            } else {
              props
                .getExploreListRequest()
                .then((res) => res && props.closeDrawer());
            }
          });
      }
    }
  }

  return (
    <Drawer
      title={
        props.edit
          ? `Update ${props.category ? "Category" : "Applications"}`
          : `Add ${props.category ? "Category" : "Applications"}`
      }
      width="500px"
      show={props.show}
      closeDrawer={props.closeDrawer}
      footerText="Add Applications"
      onSave={() => submitForm()}
    >
      <DrawerBody>
        {props.edit && props.category && (
          <DropdownInputContainer
            title="Category"
            placeholder="Select the category"
            defaultValue={props.categoryId}
            options={categories}
            edit
            passSelectedValues={(value) => getTopApps(value)}
            invalid={props.category && invalid}
          />
        )}
        {props.category ? (
          <DropdownInputContainer
            title="Applications"
            placeholder={topApps ? "No Applications" : "Select the Category"}
            defaultValue={defaultApplications}
            values={topApps}
            multi
            edit
            disabled={props.category}
            clearable
          />
        ) : (
          <DropdownInputContainer
            title="Applications"
            placeholder="Select the Applications"
            defaultValue={defaultApplications}
            options={applications}
            passSelectedValues={(value) => {
              getAppsList(value);
            }}
            multi
            edit
            invalid={!props.category && invalid}
          />
        )}
      </DrawerBody>
    </Drawer>
  );
}
export default connect(
  ({ Category, Explore, Application }) => ({
    ...Category,
    ...Explore,
    ...Application
  }),
  (dispatch) => bindActionCreators({ ...exploreActions }, dispatch)
)(ExploreDrawer);
