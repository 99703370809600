import { Section } from "services/Section";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";

const handleError = err => {
  console.log("Error in Section action : ", err);
};

// Actions
export const getSectionRequest = () => async dispatch => {
  try {
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await Section.getSection(country);
    if (data.result) {
      data.result.apps = data.dictionary.apps;
      data.result.advs = data.dictionary.advs;
      dispatch(mutation.setSection(data.result));
    }
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateSectionRequest = payload => async dispatch => {
  try {
    return await Section.updateSection(payload);
  } catch (err) {
    handleError(err);
    return err;
  }
};
