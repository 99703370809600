import { Api } from "./config/request";

export const getExploreList = (country) => {
  return Api.getRequest(
    `app-store/${
      country === "Global" || country === "All" ? `` : `?countryCode=${country}`
    }`
  );
};

export const updateExplore = (payload) => {
  return Api.putRequest(`app-store/`, payload);
};

export const Explore = {
  getExploreList,
  updateExplore
};
