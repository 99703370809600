import { Country } from "services/Country";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in Country action : ", err);
};

// Actions
export const getCountryListRequest = payload => async dispatch => {
  try {
    let data = await Country.getCountryList(payload);
    let countries = [{ value: "Global", label: "Global" }];
    Object.values(data.result).map(country =>
      countries.push({ value: country.Alpha2Code, label: country.Name })
    );
    if (countries) {
      dispatch(mutation.setCountryList(countries));
    }
    return countries;
  } catch (err) {
    handleError(err);
    return err;
  }
};
