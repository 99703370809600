import React, { useState } from "react";
import { Flexbox } from "static/coreStyles/container.styled";
import { UploadTitle, ErrorMessage } from "static/coreStyles/global.styled";
import { DragInputDiv, CarouselDiv } from "./DragInputContainer.styled";
import Dropzone from "react-dropzone";
import ImageCarousel from "components/common/ImageCarousel";
import swal from "sweetalert";

function DragInputContainer(props) {
  const [image, setImage] = useState(props.img);
  function clearImage() {
    props.multi ? props.getMultiImageInput([]) : props.getImageInput(null);
    setImage(null);
  }
  function UploadImage(e) {
    setImage(URL.createObjectURL(e.target.files[0]));
    props.getImageInput(e.target.files[0]);
  }
  function onPreviewDrop(files) {
    if (props.multi && files && files.length > 3) {
      swal({
        title: "Oops!",
        text: "Cannot Upload More than 3 Banners",
        icon: "error"
      });
    } else if (!props.multi && files && files.length > 1) {
      swal({
        title: "Oops!",
        text: "Cannot Upload More than 1 Featured Image",
        icon: "error"
      });
    } else {
      props.multi
        ? props.getMultiImageInput(files)
        : props.getImageInput(files[0]);
      let images =
        files &&
        files.map(Element => {
          return URL.createObjectURL(Element);
        });
      setImage(images);
    }
  }
  function processFiles(files) {
    return Array.from(files);
  }

  function UploadImageFileInput(e) {
    props.multi ? onPreviewDrop(processFiles(e.target.files)) : UploadImage(e);
  }

  return (
    <Flexbox flexDirection="column" marginBottom="20px">
      <Flexbox>
        <UploadTitle>{props.title}</UploadTitle>
        {props.invalid && (
          <ErrorMessage>{props.url && "Only 1 URL "}Required*</ErrorMessage>
        )}
      </Flexbox>
      {props.multi && (
        <UploadTitle multi>You can select upto 3 Banner Images</UploadTitle>
      )}
      {props.multi && image ? (
        <CarouselDiv show={image}>
          <ImageCarousel image={image} />
          <img
            alt="cross"
            src={require("static/images/cross.svg")}
            className="carousel-cross pointer"
            onClick={() => {
              clearImage();
            }}
          />
        </CarouselDiv>
      ) : null}
      <DragInputDiv
        show={image}
        multi={props.multi && image}
        invalid={props.invalid}
      >
        <div className="drag-upload">
          <Dropzone
            accept="image/*"
            onDrop={onPreviewDrop}
            multiple={props.multi}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="drag-upload"></div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <img
          src={props.imgUrl}
          alt="placeholder"
          className="toggle-visibility"
        />

        <label htmlFor="app-image" className="upload-text toggle-visibility">
          <input
            onChange={UploadImageFileInput}
            type="file"
            name="app-image"
            className="custom-file-upload toggle-visibility"
            multiple={props.multi}
          />
          {props.uploadText}
        </label>
        <div className="upload-subtext toggle-visibility">
          {props.uploadSubtext}
        </div>

        {!props.multi && (
          <img alt="category" src={image} className="uploaded-img" />
        )}

        <img
          alt="cross"
          src={require("static/images/cross.svg")}
          className="cross pointer"
          onClick={() => {
            clearImage();
          }}
        />
      </DragInputDiv>
    </Flexbox>
  );
}
export default DragInputContainer;
