import React from "react";
import { ImageCarouselContainer } from "./ImageCarousel.styled";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ImageCarousel(props) {
  const settings = {
    dots: true,
    arrow: false,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <ImageCarouselContainer banner={props.banner}>
      <Slider
        {...settings}
        className="image-carousel"
        dotsClass="carousel-dots"
      >
        {props.image &&
          props.image.map((image, index) => {
            return (
              <div className="image-container" key={index}>
                {props.banner ? (
                  <img
                    alt="banner"
                    src={image.mediaUrl}
                    className="uploaded-img"
                  />
                ) : (
                  <img alt="banner" src={image} className="uploaded-img" />
                )}
              </div>
            );
          })}
      </Slider>
    </ImageCarouselContainer>
  );
}
export default ImageCarousel;
