import React, { useState, useEffect } from "react";
import { Banner } from "./BannerDrawer.styled";
import Drawer from "components/common/Drawer";
import { DrawerBody } from "static/coreStyles/global.styled";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as exploreActions from "store/Explore/actions";
import * as applicationActions from "store/Application/actions";
import { getScope } from "utility/helperFunctions";
import swal from "sweetalert";
import { Flexbox } from "static/coreStyles/container.styled";

function BannerDrawer(props) {
  const [app, setApp] = useState(
    props.edit ? props.defaultData.app : undefined
  );
  const [selected, setSelected] = useState(
    props.edit
      ? props.defaultData &&
          props.defaultData.banner &&
          props.defaultData.banner.id
      : ""
  );
  const [loader, setLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    props.getFullApplicationListRequest();
    // eslint-disable-next-line
  }, []);

  const prevApps = props.prevApps
    ? props.prevApps.map((prevApp) => prevApp.name)
    : [];
  const applicationOptions =
    props.ApplicationList &&
    props.ApplicationList.reduce(function (filtered, option) {
      if (option.isActive && !prevApps.includes(option.name)) {
        filtered.push({ label: option.name, value: option.id, app: option });
      }
      return filtered;
    }, []);
  function AddBanner() {
    setLoader(true);
    let list = props.ExploreList.banners ? props.ExploreList.banners : [];

    if (props.edit) {
      list[props.defaultData.index] = { appId: app.app.id, bannerId: selected };
    } else {
      list.push({ appId: app.app.id, bannerId: selected });
    }
    let payload = {
      scope: getScope(true),
      banners: list
    };
    props.updateExploreRequest(payload).then((res) => {
      if (res.error) {
        setLoader(false);
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        setLoader(false);
        props.getExploreListRequest();
        props.closeDrawer();
      }
    });
  }
  function RemoveBanner() {
    setRemoveLoader(true);
    let list = props.ExploreList.banners ? props.ExploreList.banners : [];
    list = [
      ...list.slice(0, props.defaultData.index),
      ...list.slice(props.defaultData.index + 1, list.length)
    ];
    let payload = {
      scope: getScope(true),
      banners: list
    };
    props.updateExploreRequest(payload).then((res) => {
      if (res) {
        setRemoveLoader(false);
      }
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        props.getExploreListRequest();
        props.closeDrawer();
      }
    });
  }

  function validateForm() {
    if (app && selected && selected.length) {
      AddBanner();
    } else {
      setInvalid(true);
    }
  }
  return (
    <Drawer
      title={`${props.edit ? "Edit" : "Add"} Application Banner`}
      width="500px"
      show={props.show}
      closeDrawer={props.closeDrawer}
      footerText="Save"
      onSave={validateForm}
      remove={props.edit}
      onRemove={RemoveBanner}
      loader={loader}
      removeLoader={removeLoader}
    >
      <DrawerBody>
        <DropdownInputContainer
          title="Application"
          placeholder={"Select Application"}
          options={applicationOptions}
          passSelectedValues={(val) => setApp(val)}
          defaultValue={app}
          edit={props.edit}
          noAlter
          invalid={invalid && !app}
        />
        {app ? (
          <Flexbox>
            {app.app.images &&
            app.app.images.banners &&
            app.app.images.banners.length > 0
              ? app.app.images.banners.map((banner) => {
                  return (
                    <Banner
                      onClick={() => setSelected(banner.id)}
                      isSelected={selected === banner.id}
                      key={banner.id}
                      invalid={invalid && !selected}
                    >
                      <img src={banner.mediaUrl} alt={app.app.name} />
                    </Banner>
                  );
                })
              : null}
          </Flexbox>
        ) : null}
      </DrawerBody>
    </Drawer>
  );
}
export default connect(
  ({ Explore, Application }) => ({
    ...Explore,
    ...Application
  }),
  (dispatch) =>
    bindActionCreators({ ...exploreActions, ...applicationActions }, dispatch)
)(BannerDrawer);
