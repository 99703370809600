import React, { Component } from "react";
import { Container, SubContainer, RememberMe } from "./Login.styled";
import { validateEmail } from "utility/helperFunctions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginActions from "store/Login/actions";
import swal from "sweetalert";
import { storage as LocalStorage } from "services/config/storage";
import { withRouter } from "react-router-dom";
import * as countryActions from "store/Country/actions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: "",
      passwordError: "",
      email: "",
      password: "",
      loading: false
    };
  }
  componentDidMount() {
    LocalStorage.fetch.authToken() && this.props.history.push("/");
  }
  submit = () => {
    if (
      this.state.passwordError === "login-validated" &&
      this.state.emailError === "login-validated"
    ) {
      this.login();
    }
  };

  login = async () => {
    const { email, password } = this.state;
    await this.setState({ loading: true });
    this.props
      .adminLoginRequest({
        email: email,
        password: password
      })
      .then(res => {
        this.setState({ loading: false });
        if (res.error) {
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res.result) {
          this.props.history.push("/");
          this.props.getCountryListRequest();
        }
      });
  };

  handleChange = async e => {
    await this.setState({
      [e.target.name]: e.target.value
    });
    const { email, password } = this.state;
    if (!email) {
      this.setState({ emailError: "" });
    } else if (!validateEmail(email) && email) {
      this.setState({ emailError: "login-error" });
    } else if (email) {
      this.setState({ emailError: "login-validated" });
    }
    if (!password) {
      this.setState({ passwordError: "" });
    } else if (password.length < 6 && password) {
      this.setState({ passwordError: "login-error" });
    } else if (password) {
      this.setState({ passwordError: "login-validated" });
    }
  };

  render() {
    return (
      <Container>
        <SubContainer>
          <img
            alt="messenger-pro-logo"
            src={require("static/images/messenger-pro-icon.svg")}
          />
          <div className="title">Messenger Pro</div>
          <h1 className="head">Hello!</h1>
          <h4 className="sub-heading">
            Welcome, please log in to your account!
          </h4>
          <h6 className="input-head">Email Address</h6>
          <input
            type="text"
            className={`login-input ${this.state.emailError}`}
            placeholder="Enter your email address"
            name="email"
            autoComplete="off"
            onChange={this.handleChange}
          />
          <h6 className="input-head">Password</h6>
          <div className="relative">
            <input
              type="password"
              className={`login-input ${this.state.passwordError}`}
              placeholder="Enter your password"
              name="password"
              autoComplete="off"
              onChange={this.handleChange}
            />
            <div className="forget">Forgot ?</div>
          </div>
          <RememberMe>
            <label className="container">
              Remember me
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </RememberMe>
          <button
            className="login"
            onClick={() => {
              this.submit();
            }}
          >
            {this.state.loading ? (
              <img
                alt="loader"
                src={require("static/images/loader-button.svg")}
                className="loader"
              />
            ) : (
              "Log In"
            )}
          </button>
        </SubContainer>
      </Container>
    );
  }
}
export default withRouter(
  connect(
    ({ Login, Country }) => ({ ...Login, ...Country }),
    dispatch =>
      bindActionCreators({ ...loginActions, ...countryActions }, dispatch)
  )(Login)
);
