import React, { useState, useEffect } from "react";
import { Flexbox } from "static/coreStyles/container.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import { ItemContainer, ExploreRowContainer } from "./ExploreRow.styled";
import ExploreDrawer from "components/DrawerModules/ExploreDrawer";
//import ViewAll from "components/ViewAll";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonButton } from "static/coreStyles/global.styled";
import * as categoryActions from "store/Category/actions";
import * as exploreActions from "store/Explore/actions";
import * as applicationActions from "store/Application/actions";
import BannerDrawer from "components/DrawerModules/BannerDrawer";
import { ReactSortable } from "react-sortablejs";
import { getScope } from "utility/helperFunctions";
import swal from "sweetalert";

function ExploreRow(props) {
  //const [viewApps, setViewApps] = useState(false);
  const [editState, setEditState] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [showExploreDrawer, setShowExploreDrawer] = useState(false);
  const [showBannerDrawer, setShowBannerDrawer] = useState(false);
  const [data, setData] = useState(props.apps);
  const [reordered, setReordered] = useState(false);
  const [loader, setLoader] = useState(false);

  const title = props.category
    ? props.ExploreList.categories[props.category].name
    : props.name;

  function EditBanner(index, data) {
    let app = { label: data.name, value: data.app.id, app: data.app };
    setDefaultData({ index: index, banner: data.icon, app: app });
    setShowBannerDrawer(true);
    setEditState(true);
  }

  useEffect(() => {
    setData(props.apps);
  }, [props.apps]);

  const saveList = () => {
    setLoader(true);
    let appIds = data.map((app) => app.id);

    let payload = {
      scope: getScope(true)
    };

    switch (props.name) {
      case "Featured Apps":
        payload.featuredApps = appIds;
        break;
      case "Try something new":
        payload.tryNew = appIds;
        break;
      case "People are Loving":
        payload.peopleAreLoving = appIds;
        break;
      default:
        break;
    }
    props.updateExploreRequest(payload).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else {
        props.getExploreListRequest().then((res) => {
          if (res) {
            setLoader(false);
            setReordered(false);
          }
        });
      }
    });
  };
  return (
    <ExploreRowContainer
      banner={props.banner}
      reorderable={
        props.name === "Featured Apps" ||
        props.name === "Try something new" ||
        props.name === "People are Loving"
      }
    >
      {props.isEmpty ? (
        <TitleContainer
          onClick={() => {
            setShowExploreDrawer(true);
            setEditState(true);
          }}
          className="pointer"
        >
          <img
            alt="add"
            src={require("static/images/add-black.svg")}
            style={{ margin: "0 10px 2px 0px" }}
          />
          <div>Add Category</div>
        </TitleContainer>
      ) : (
        <Flexbox>
          <ItemContainer justifyContent="flex-start">
            <TitleContainer>
              <div>
                <div>{title ? title : ""}</div>
                <div className="subtext">{`${
                  props.apps ? props.apps.length : "0"
                } ${props.banner ? "Banner" : "Application"}${
                  props.apps && props.apps.length > 0 ? "s" : ""
                }`}</div>
              </div>
            </TitleContainer>
          </ItemContainer>
          {reordered && (
            <CommonButton
              marginRight="20px"
              height="35px"
              onClick={saveList}
              width="95px"
              app
            >
              {loader ? (
                <img
                  alt="loader"
                  src={require("static/images/loader-button.svg")}
                  className="loader"
                />
              ) : (
                "Save"
              )}
            </CommonButton>
          )}
          {props.banner ? null : (
            <img
              onClick={() => {
                setShowExploreDrawer(true);
                setEditState(true);
              }}
              className="ml-25 pointer"
              src={require("static/images/edit.svg")}
              alt="edit"
            />
          )}
        </Flexbox>
      )}
      <Flexbox paddingTop="20px" overflowX="auto">
        {((!props.top && props.apps && !props.apps.length) || props.banner) && (
          <Flexbox
            marginTop="10px"
            flexDirection="column"
            alignItems="center"
            onClick={() => {
              props.banner
                ? setShowBannerDrawer(true)
                : setShowExploreDrawer(true);
              setEditState(false);
            }}
          >
            <div className="add-new pointer">
              <img
                alt="plus"
                src={require("static/images/add-circle.svg")}
                className="add-new-circle"
              />
            </div>
            <div className="app-name app-edge">Add New</div>
          </Flexbox>
        )}
        {props.name === "Featured Apps" ||
        props.name === "Try something new" ||
        props.name === "People are Loving" ? (
          <ReactSortable
            list={data}
            setList={setData}
            onChange={() => setReordered(true)}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            className="reorder"
          >
            {data &&
              data.map((app, index) => {
                return (
                  <div className="app-icon-container" key={index}>
                    {props.banner ? (
                      <img
                        alt="edit"
                        className="edit-section"
                        src={require("static/images/edit-section.svg")}
                        onClick={() => EditBanner(index, app)}
                      />
                    ) : null}

                    {app.ad && <div className="ad-icon">AD</div>}
                    <Flexbox flexDirection="column" alignItems="center">
                      <img
                        alt="app"
                        src={app.icon && app.icon.mediaUrl}
                        className="application"
                      />
                      <div className="app-name">{app.name}</div>
                    </Flexbox>
                  </div>
                );
              })}
          </ReactSortable>
        ) : (
          data &&
          data.map((app, index) => {
            return (
              <div className="app-icon-container" key={index}>
                {props.banner ? (
                  <img
                    alt="edit"
                    className="edit-section"
                    src={require("static/images/edit-section.svg")}
                    onClick={() => EditBanner(index, app)}
                  />
                ) : null}

                {app.ad && <div className="ad-icon">AD</div>}
                <Flexbox flexDirection="column" alignItems="center">
                  <img
                    alt="app"
                    src={app.icon && app.icon.mediaUrl}
                    className="application"
                  />
                  <div className="app-name">{app.name}</div>
                </Flexbox>
              </div>
            );
          })
        )}
        {/* {apps && apps.length > 0 && (
          <div
            className="app-icon-container pointer"
            onClick={() => {
              setViewApps(!viewApps);
            }}
          >
            {!props.category && (
              <Flexbox flexDirection="column" alignItems="center">
                <div className="add-new">
                  <div className="view-all">+4</div>
                </div>
                <div className="app-name app-edge">View All</div>
              </Flexbox>
            )}
          </div>
        )} */}
      </Flexbox>

      {/* {viewApps && (
        <ViewAll
          data={props.data}
          show={viewApps}
          closeModal={() => {
            setViewApps(false);
          }}
        />
      )} */}

      {showExploreDrawer && (
        <ExploreDrawer
          apps={props.apps}
          categoryId={props.category}
          rowName={props.name}
          category={props.top}
          index={props.index}
          edit={editState}
          show={showExploreDrawer}
          closeDrawer={() => setShowExploreDrawer(false)}
        />
      )}
      {showBannerDrawer && (
        <BannerDrawer
          show={showBannerDrawer}
          closeDrawer={() => setShowBannerDrawer(false)}
          edit={editState}
          defaultData={defaultData}
          prevApps={props.apps}
        />
      )}
    </ExploreRowContainer>
  );
}

export default connect(
  ({ Explore, Category, Application }) => ({
    ...Explore,
    ...Category,
    Application
  }),
  (dispatch) =>
    bindActionCreators(
      { ...exploreActions, ...categoryActions, applicationActions },
      dispatch
    )
)(ExploreRow);
