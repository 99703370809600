import React, { useState, useEffect } from "react";
import {
  Container,
  Parent,
  ApplicationSubContainer
} from "./CategoryDetail.styled";
import { ButtonFilter } from "static/coreStyles/global.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import Switch from "components/common/Switch";
import { Banner } from "static/coreStyles/global.styled";
import TopApplicationsDrawer from "components/DrawerModules/TopApplicationsDrawer";
import ApplicationDrawer from "components/DrawerModules/ApplicationDrawer";
import UploadImageModal from "components/UploadImageModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import Loader from "components/common/Loader";
import ImageCarousel from "components/common/ImageCarousel";
import swal from "sweetalert";
import { getScope } from "utility/helperFunctions";
import CategoryApplicationsDrawer from "components/DrawerModules/CategoryApplicationsDrawer";
import SubcategoryRow from "components/SubcategoryRow";
import { storage as LocalStorage } from "services/config/storage";
import { ReactSortable } from "react-sortablejs";
import { CommonButton } from "static/coreStyles/global.styled";

function CategoryDetail(props) {
  let detail = props.CategoryDetail;
  const [showTopAppsDrawer, setShowTopAppsDrawer] = useState(false);
  const [showCategoryAppsDrawer, setShowCategoryAppsDrawer] = useState([
    false,
    ""
  ]);
  const [showAppDrawer, setShowAppDrawer] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageLoader, setImageLoader] = useState(false);
  const [appLoader, setAppLoader] = useState(false);
  const [activeState, setActiveState] = useState(
    detail.category && detail.category.isActive
  );

  const [countryFilter, setCountryFilter] = useState("All");
  const [activeFilter, setActiveFilter] = useState("All");
  const [showAllApps, setShowAllApps] = useState(false);
  const [topApps, setTopApps] = useState(
    detail.topApps && detail.topApps.length
      ? detail.topApps.map((app) => {
          return { appId: app };
        })
      : []
  );
  const [reordered, setReordered] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setTopApps(
      detail.topApps &&
        detail.topApps.map((app) => {
          return { appId: app };
        })
    );
    // eslint-disable-next-line
  }, [detail && detail.topApps]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    props
      .getCategoryDetailRequest(
        props.match && props.match.params && props.match.params.id
      )
      .then((res) => {
        if (res) {
          setLoading(false);
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setActiveState(detail.category && detail.category.isActive);
    // eslint-disable-next-line
  }, [detail.category && detail.category.isActive]);

  function changeCountryFilter(country) {
    country === countryFilter
      ? setCountryFilter("All")
      : setCountryFilter(country);
  }

  function changeActiveFilter(isActive) {
    isActive === activeFilter
      ? setActiveFilter("All")
      : setActiveFilter(isActive);
  }

  function changeActiveStatus() {
    props
      .changeCategoryActiveStatusRequest(props.match.params.id, !activeState)
      .then((res) => {
        if (res.error) {
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res.result) {
          props.getCategoryDetailRequest(props.match.params.id);
          props.getCategoryListRequest();
        }
      });
    setActiveState(!activeState);
  }

  function updateBannerImages(value) {
    setImageLoader(true);
    let payload = {
      scope: getScope(true),
      sectionType: 2,
      banners: value.length
        ? value.map((val) => {
            return { appId: val.appId, bannerId: val.id };
          })
        : value
    };
    props
      .updateCategoryDetailRequest(
        props.match.params && props.match.params.id,
        payload
      )
      .then((res) => {
        if (res.error) {
          setImageLoader(false);
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else {
          props
            .getCategoryDetailRequest(
              props.match.params && props.match.params.id
            )
            .then((res) => res && setImageLoader(false));
        }
      });
  }

  function hideShowApp(appId, hide) {
    setAppLoader(true);
    let banners = [];
    detail.banners &&
      detail.banners.forEach((banner) => {
        if (banner.appId !== appId) {
          banners.push(detail.bannerOptions[banner.bannerId]);
        }
      });

    updateBannerImages(banners);

    let payload = {
      scope: getScope(true),
      sectionType: 1,
      apps: hide
        ? detail.topApps.filter((app) => app !== appId)
        : detail.allApps[appId].isActive
        ? [...detail.topApps, appId]
        : detail.topApps
    };
    props
      .updateCategoryDetailRequest(props.match.params.id, payload)
      .then((res) => {
        if (res.error) {
          setAppLoader(false);
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else {
          props.getCategoryDetailRequest(props.match.params.id);
          props.getCategoryListRequest();
          setAppLoader(false);
        }
      });

    detail.subCategories.forEach((subCat) => {
      let payload = {
        scope: getScope(true),
        sectionType: 3,
        apps: hide
          ? subCat.apps.filter((app) => app !== appId)
          : detail.allApps[appId].isActive
          ? [...subCat.apps, appId]
          : subCat.apps,
        subCategoryId: subCat.id
      };
      detail.allApps[appId].subCategoryIds.indexOf(subCat.id) > -1 &&
        props
          .updateCategoryDetailRequest(props.match.params.id, payload)
          .then((res) => {
            if (res.error) {
              setAppLoader(false);
              swal({
                title: "Oops!",
                text: res.error.message,
                icon: "error"
              });
            } else {
              props.getCategoryDetailRequest(props.match.params.id);
              props.getCategoryListRequest();
              setAppLoader(false);
            }
          });
    });
  }

  function reorderTopApps() {
    setLoader(true);
    let reorderedApps = topApps.map((app) => app.appId);
    let payload = {
      scope: getScope(true),
      sectionType: 1,
      apps: reorderedApps
    };
    props
      .updateCategoryDetailRequest(props.match.params.id, payload)
      .then((res) => {
        if (res.error) {
          setLoader(false);
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else {
          setLoader(false);
          props.getCategoryDetailRequest(props.match.params.id);
          props.getCategoryListRequest();
          setReordered(false);
        }
      });
  }

  function redirectToParent() {
    props.history.push("/categories");
  }

  return (
    <Parent>
      <Container>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox
                onClick={() => {
                  redirectToParent();
                }}
                className="pointer"
              >
                <img src={require("static/images/left-arrow.svg")} alt="back" />
                <p className="back">Back</p>
              </Flexbox>
              <Switch
                state={
                  activeState === undefined
                    ? detail.category && detail.category.isActive
                    : activeState
                }
                changeStatus={changeActiveStatus}
              />
            </Flexbox>
            <Banner
              show={detail && detail.banners && detail.banners.length > 0}
            >
              {imageLoader ? (
                <Loader />
              ) : (
                <>
                  <img
                    alt="category-img"
                    src={detail.category && detail.category.icon.mediaUrl}
                    className="category-img"
                  />

                  <div className="category-name">
                    {detail.category && detail.category.name}
                  </div>

                  <div
                    className="banner-placeholder"
                    onClick={() => setShowUploadImageModal(true)}
                  >
                    <img
                      className="banner-placeholder-image"
                      src={require("static/images/banner-placeholder.svg")}
                      alt="upload"
                    />
                    <div>
                      <label className="banner-upload">Upload</label> banner
                      illustration here
                    </div>
                  </div>

                  {detail && detail.banners && detail.banners.length > 0 && (
                    <ImageCarousel
                      image={detail.banners.map((banner) => {
                        return detail.bannerOptions[banner.bannerId];
                      })}
                      banner
                    />
                  )}
                  <button
                    className="banner-edit-button pointer"
                    onClick={() => setShowUploadImageModal(true)}
                  >
                    <img
                      className="banner-edit-icon"
                      src={require("static/images/edit.svg")}
                      alt="edit"
                    />
                    <label htmlFor="file" className="pointer">
                      EDIT
                    </label>
                  </button>
                </>
              )}
            </Banner>
            <div>
              <Flexbox
                margin="50px 0 30px"
                height="35px"
                justifyContent="space-between"
              >
                <Flexbox>
                  <div className="bar"></div>
                  <h5 className="top-apps">Top 10 Applications</h5>
                </Flexbox>
                <Flexbox justifyContent="flex-end">
                  {reordered && (
                    <CommonButton
                      marginRight="20px"
                      height="35px"
                      onClick={reorderTopApps}
                      width="95px"
                      app
                    >
                      {loader ? (
                        <img
                          alt="loader"
                          src={require("static/images/loader-button.svg")}
                          className="loader"
                        />
                      ) : (
                        "Save"
                      )}
                    </CommonButton>
                  )}
                  {detail && topApps && topApps.length > 0 && (
                    <img
                      src={require("static/images/edit.svg")}
                      alt="edit"
                      className="pointer"
                      onClick={() => setShowTopAppsDrawer(true)}
                    />
                  )}
                </Flexbox>
              </Flexbox>
              <Flexbox overflowX="auto">
                {detail && topApps && !topApps.length && (
                  <Flexbox
                    flexDirection="column"
                    alignItems="center"
                    margin="0 30px 20px 0"
                  >
                    <div
                      className="add-new pointer"
                      onClick={() => {
                        setShowTopAppsDrawer(true);
                      }}
                    >
                      <img
                        alt="plus"
                        src={require("static/images/add-circle.svg")}
                        className="add-new-circle"
                      />
                    </div>
                    <div className="app-name app-edge">Add New</div>
                  </Flexbox>
                )}
                <ReactSortable
                  list={topApps}
                  setList={setTopApps}
                  onChange={() => setReordered(true)}
                  animation={200}
                  delayOnTouchStart={true}
                  delay={2}
                  className="reorder"
                >
                  {topApps && topApps.length > 0
                    ? topApps.map((app, index) => {
                        app = detail.allApps[app.appId];
                        return (
                          <Flexbox
                            flexDirection="column"
                            alignItems="center"
                            key={index}
                            margin="2px 30px 2px 0"
                            marginLast="0"
                            padding="10px 0"
                            className="sortable"
                          >
                            <img
                              alt={app.name}
                              src={app.icon && app.icon.mediaUrl}
                              className="app-img"
                            />
                            <div className="app-name">{app.name}</div>
                          </Flexbox>
                        );
                      })
                    : null}
                </ReactSortable>
              </Flexbox>
              <ApplicationSubContainer margin="30px 0 0" expanded={showAllApps}>
                <Flexbox justifyContent="space-between">
                  <Flexbox>
                    <h4>
                      All Applications{" "}
                      {detail.allApps && Object.values(detail.allApps).length
                        ? ` (` + Object.values(detail.allApps).length + `)`
                        : ""}
                    </h4>
                    {LocalStorage.fetch.countryData() &&
                      LocalStorage.fetch.countryData().label !== "Global" && (
                        <>
                          <ButtonFilter
                            className="pointer"
                            toggle={countryFilter === true}
                            onClick={() => changeCountryFilter(true)}
                          >
                            Global
                          </ButtonFilter>
                          <ButtonFilter
                            className="pointer"
                            toggle={countryFilter === false}
                            onClick={() => changeCountryFilter(false)}
                          >
                            {LocalStorage.fetch.countryData().label}
                          </ButtonFilter>
                        </>
                      )}
                    <>
                      <ButtonFilter
                        className="pointer"
                        toggle={activeFilter === true}
                        onClick={() => changeActiveFilter(true)}
                      >
                        Active
                      </ButtonFilter>
                      <ButtonFilter
                        className="pointer"
                        toggle={activeFilter === false}
                        onClick={() => changeActiveFilter(false)}
                      >
                        Inactive
                      </ButtonFilter>
                    </>
                  </Flexbox>
                  <Flexbox justifyContent="flex-end" marginBottom="20px">
                    <img
                      alt="arrow"
                      className="arrow"
                      src={require("static/images/dropdown-arrow.svg")}
                      onClick={() => setShowAllApps(!showAllApps)}
                    />
                  </Flexbox>
                </Flexbox>
                <Flexbox flexFlow="row wrap">
                  <Flexbox
                    flexDirection="column"
                    alignItems="center"
                    margin="0 30px 30px 0"
                    onClick={() => {
                      setShowAppDrawer(true);
                    }}
                  >
                    <div className="add-new pointer">
                      <img
                        alt="plus"
                        src={require("static/images/add-circle.svg")}
                        className="add-new-circle"
                      />
                    </div>
                    <div className="app-name app-edge pointer">Add New</div>
                  </Flexbox>
                  {detail.allApps && Object.keys(detail.allApps).length
                    ? Object.keys(detail.allApps)
                        .filter(
                          (app) =>
                            (detail.allApps[app].scope.isGlobal ===
                              countryFilter ||
                              countryFilter === "All") &&
                            (detail.allApps[app].isActive === activeFilter ||
                              activeFilter === "All")
                        )
                        .map((app, index) => {
                          let id = app;
                          app = detail.allApps[app];
                          return (
                            <Flexbox
                              flexDirection="column"
                              alignItems="center"
                              key={index}
                              margin="0 30px 30px 0"
                              className="app-container"
                            >
                              {detail.allApps[id].isActive ? (
                                app.isVisibleInThisScope ? (
                                  <img
                                    alt="hide"
                                    src={require("static/images/visible.svg")}
                                    className="visible pointer"
                                    onClick={() => hideShowApp(id, true)}
                                  />
                                ) : (
                                  <img
                                    alt="show"
                                    src={require("static/images/hidden.svg")}
                                    className="visible pointer"
                                    onClick={() => hideShowApp(id, false)}
                                  />
                                )
                              ) : null}
                              <img
                                alt={app.name}
                                className="app-img"
                                src={app.icon && app.icon.mediaUrl}
                              />
                              <div className="app-name">{app.name}</div>
                            </Flexbox>
                          );
                        })
                    : null}
                </Flexbox>
              </ApplicationSubContainer>
              {detail.subCategories &&
                detail.subCategories.map((category, index) => {
                  return (
                    <SubcategoryRow
                      key={index}
                      category={category}
                      categoryId={props.match.params.id}
                      detail={detail}
                      loader={appLoader}
                      removeApp={(id) => hideShowApp(id, true)}
                      showCategoryAppsDrawer={() =>
                        setShowCategoryAppsDrawer([true, category])
                      }
                    />
                  );
                })}
            </div>
          </>
        )}
        {showCategoryAppsDrawer[0] && (
          <CategoryApplicationsDrawer
            show={showCategoryAppsDrawer[0]}
            subCategory={showCategoryAppsDrawer[1]}
            categoryId={props.match.params.id}
            closeDrawer={() => {
              setShowCategoryAppsDrawer([false, ""]);
            }}
          />
        )}
        {showTopAppsDrawer && (
          <TopApplicationsDrawer
            show={showTopAppsDrawer}
            categoryId={props.match.params.id}
            closeDrawer={() => {
              setShowTopAppsDrawer(false);
            }}
          />
        )}
        {showUploadImageModal && (
          <UploadImageModal
            defaultValues={
              detail.banners &&
              detail.banners.map((banner) => {
                return detail.bannerOptions[banner.bannerId];
              })
            }
            detail={detail}
            show={showUploadImageModal}
            bannerOptions={detail.bannerOptions}
            closeModal={() => {
              setShowUploadImageModal(false);
            }}
            getImages={updateBannerImages}
          />
        )}
        {showAppDrawer && (
          <ApplicationDrawer
            categoryDetailScreen={{
              value: props.match.params && props.match.params.id,
              label:
                props.CategoryDetail.category &&
                props.CategoryDetail.category.name
            }}
            show={showAppDrawer}
            closeDrawer={() => {
              setShowAppDrawer(false);
            }}
          />
        )}
      </Container>
    </Parent>
  );
}
export default connect(
  ({ Category }) => ({ ...Category }),
  (dispatch) => bindActionCreators({ ...categoryActions }, dispatch)
)(CategoryDetail);
