import { Search } from "services/Search";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";

const handleError = (err) => {
  console.log("Error in Search action : ", err);
};

// Actions
export const searchListRequest = (search, list) => async (dispatch) => {
  try {
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = [];
    if (list) {
      data = Search.searchList(search, list);
    } else {
      switch (window.location.pathname) {
        case "/users":
          data = search.match(/^[0-9]{10}$/)
            ? await Search.getUserSearchList(search, country, true)
            : await Search.getUserSearchList(search, country);
          data = data.result;
          break;
        case "/applications":
          data = await Search.getApplicationSearchList(search, country);
          data = data.result;
          break;
        case "/advertisements":
          data = await Search.getAdvertisementSearchList(search, country);
          data = data.result;
          break;
        default:
          break;
      }
    }
    dispatch(mutation.setSearchList(data));
    dispatch(mutation.setSearchComplete(true));
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

function ltrim(str) {
  if (!str) return str;
  return str.replace(/^\s+/g, "");
}
export const setSearchRequest = (query) => async (dispatch) => {
  try {
    query = ltrim(query);
    dispatch(mutation.setSearchQuery(query));
    if (!query.length) {
      dispatch(mutation.setSearchComplete(false));
      dispatch(mutation.setSearchList([]));
    }
    return true;
  } catch (err) {
    handleError(err);
    return err;
  }
};
