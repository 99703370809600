export const adStickers = [
  {
    name: "Sticker 1",
    url:
      "https://messenger-pro-dev.s3.ap-south-1.amazonaws.com/1594045011045-ad1.svg"
  },
  {
    name: "Sticker 2",
    url:
      "https://messenger-pro-dev.s3.ap-south-1.amazonaws.com/1594044970693-ad2.svg"
  },
  {
    name: "Sticker 3",
    url:
      "https://messenger-pro-dev.s3.ap-south-1.amazonaws.com/1594041981343-ad3.svg"
  }
];
