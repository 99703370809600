export const menu = [
  {
    path: "/",
    img: require("static/images/menu-icons/chart-pie.svg"),
    alt: "dashboard"
  },
  {
    path: "/users",
    img: require("static/images/menu-icons/user-circle.svg"),
    alt: "users"
  },
  {
    path: "/categories",
    img: require("static/images/menu-icons/categories.svg"),
    alt: "categories",
    include: true
  },
  {
    path: "/applications",
    img: require("static/images/menu-icons/applications.svg"),
    alt: "applications"
  },
  {
    path: "/sections",
    img: require("static/images/menu-icons/sections.svg"),
    alt: "sections"
  },
  {
    path: "/advertisements",
    img: require("static/images/menu-icons/module.svg"),
    alt: "advertisements"
  },
  {
    path: "/explore",
    img: require("static/images/menu-icons/explore.svg"),
    alt: "explore"
  }
];
