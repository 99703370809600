import styled from "styled-components";
import { Theme } from "static/coreStyles/theme.styled";
export const Banner = styled.div`
  width: 140px;
  height: 90px;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  border: ${(props) =>
    props.isSelected
      ? `solid 1px ${Theme.primaryColor}`
      : props.invalid
      ? `solid 1px rgba(255, 0, 0, 0.60)`
      : `solid 1px rgba(26, 65, 106, 0.12)`};
  :last-child {
    margin-right: 0;
  }
  img {
    width: 120px;
    height: 70px;
    object-fit: contain;
  }
`;
