import styled from "styled-components";
import { FontFamily, Size, Theme } from "static/coreStyles/theme.styled";

export const InputField = styled.input`
  width: ${(props) => (props.width ? props.width : " 100%")};
  height: 50px;
  border-radius: 6px;
  border: solid 1px
    ${(props) =>
      props.invalid
        ? "rgba(255, 0, 0, 0.60)"
        : "rgba(26, 65, 106, 0.12)"} !important;
  font-weight: 600;
  margin-top: 12px;
  background: ${Theme.white};
  font-family: ${FontFamily.SemiBold};
  font-size: ${Size.base};
  color: #212121;
  cursor: text;
  padding: ${(props) => (props.padding ? props.padding : "14px")};
  ::placeholder {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.base};
    font-weight: 500;
    color: rgba(33, 33, 33, 0.2);
  }
`;

export const TextField = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 6px;
  border: solid 1px
    ${(props) =>
      props.invalid
        ? "rgba(255, 0, 0, 0.60)"
        : "rgba(26, 65, 106, 0.12)"} !important;
  font-weight: 600;
  margin-top: 12px;
  font-family: ${FontFamily.SemiBold};
  font-size: ${Size.base};
  color: #212121;
  cursor: text;
  padding: 14px;
  outline: 0;
  resize: none;
  ::placeholder {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.base};
    font-weight: 500;
    color: rgba(33, 33, 33, 0.2);
  }
`;
