import React, { useState, useEffect } from "react";
import { CommonButton } from "static/coreStyles/global.styled";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import CategoryRow from "components/CategoryRow";
import CategoryDrawer from "components/DrawerModules/CategoryDrawer";
import NoResults from "components/common/NoResults";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import Loader from "components/common/Loader";
import PaginationLoader from "components/common/PaginationLoader";
import swal from "sweetalert";

function Categories(props) {
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageLoader, setPageLoader] = useState(false);

  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
      props.CategoryPage &&
      props.CategoryPage.next &&
      !pageLoader &&
      !loading
    ) {
      setPageLoader(true);
      props.getCategoryListRequest(props.CategoryPage.next).then((res) => {
        if (res.error) {
          setPageLoader(false);
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res) {
          setPageLoader(false);
        }
      });
    }
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    props.getCategoryListRequest().then((res) => {
      if (res) {
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, []);
  let CategoryList =
    props.SearchQuery &&
    props.SearchQuery.length > 2 &&
    props.SearchComplete === true
      ? props.SearchList
      : props.CategoryList;

  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Categories{" "}
          <span>
            {CategoryList &&
              CategoryList.length > 0 &&
              `(` + CategoryList.length + `)`}
          </span>
        </h1>
        <Flexbox>
          <CommonButton
            app
            onClick={() => {
              setShowCategoryDrawer(true);
            }}
          >
            Add Category
          </CommonButton>
        </Flexbox>
      </Flexbox>
      <>
        {loading ? (
          <Loader />
        ) : CategoryList && CategoryList.length > 0 ? (
          CategoryList.map((category, index) => {
            return <CategoryRow data={category} key={index}></CategoryRow>;
          })
        ) : (
          <NoResults
            title="categories"
            image={require("static/images/empty-categories.svg")}
          />
        )}
      </>
      {showCategoryDrawer && (
        <CategoryDrawer
          show={showCategoryDrawer}
          closeDrawer={() => {
            setShowCategoryDrawer(false);
          }}
        />
      )}
      {pageLoader && <PaginationLoader />}
    </Container>
  );
}
export default connect(
  ({ Category, Search }) => ({ ...Category, ...Search }),
  (dispatch) => bindActionCreators({ ...categoryActions }, dispatch)
)(Categories);
