import styled from "styled-components";
import { Theme } from "static/coreStyles/theme.styled";
import { FontFamily } from "static/coreStyles/theme.styled";
export const RadioToolbar = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-family: ${FontFamily.Medium};
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    display: inline-block;
    color: rgba(34, 34, 34, 0.5);
    background-color: ${Theme.secondaryColor};
    padding: 16px 5px;
    font-size: 14px;
    border-radius: 6px;
    width: 150px;
    margin-right: 10px;
    text-align: center;
    :last-child {
      margin-right: 0;
    }
  }

  input[type="radio"]:checked + label {
    background-color: ${Theme.primaryColor};
    color: ${Theme.white};
    font-weight: 600;
  }
`;
