import React, { useState, useRef, useEffect } from "react";
import debounce from "debounce";
import {
  SearchContainer,
  SearchInput,
  UserDropdown,
  customStyles,
  UserDropdownContent
} from "./Header.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import Select, { components } from "react-select";
import { storage as LocalStorage } from "services/config/storage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as searchActions from "store/Search/actions";
import { withRouter } from "react-router-dom";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img alt="dropdown" src={require("static/images/dropdown-arrow.svg")} />
    </components.DropdownIndicator>
  );
};
function Header(props) {
  function changeCountry(value) {
    LocalStorage.set.countryData(value);
    window.location.reload();
  }
  const ProfileNode = useRef(null);
  const [countryOptions, setCountryOptions] = useState([...props.CountryList]);

  function handleClick(e) {
    if (ProfileNode.current && !ProfileNode.current.contains(e.target)) {
      setOpenProfileDropdown(false);
    }
  }

  useEffect(() => {
    setCountryOptions([{ value: "All", label: "All" }, ...countryOptions]);
    document.addEventListener("click", handleClick, false);
    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
    // eslint-disable-next-line
  }, []);
  const [openProfileDropdown, setOpenProfileDropdown] = useState(false);

  function searchList(value) {
    let list = [];
    props.setSearchRequest(value);
    if (value && value.length > 2) {
      if (window.location.pathname === "/categories") {
        list = props.CategoryList;
      } else {
        list = false;
      }
      props.searchListRequest(value, list);
    }
  }

  function logout(e) {
    e.stopPropagation();
    LocalStorage.destroy.countryData();
    LocalStorage.destroy.authToken();
    LocalStorage.destroy.persistStore();
    props.history.push("/login");
  }

  function getCountry() {
    if (
      LocalStorage.fetch.countryData() &&
      LocalStorage.fetch.countryData().label === "All"
    ) {
      LocalStorage.set.countryData(props.CountryList[0]);
      return props.CountryList[0];
    } else if (LocalStorage.fetch.countryData()) {
      return LocalStorage.fetch.countryData();
    } else {
      return props.CountryList[0];
    }
  }

  function contains(target, pattern) {
    var value = 0;
    pattern.forEach(function (word) {
      value = value + target.includes(word);
    });
    return value === 1;
  }

  let showSearchBar = !contains(window.location.pathname, [
    "/sections",
    "/explore",
    "/categories/"
  ]);

  let showAllResults =
    ["/users", "/applications"].indexOf(window.location.pathname) >= 0
      ? true
      : false;
  return (
    <SearchContainer showSearchBar={showSearchBar}>
      <SearchInput
        placeholder="Search"
        value={props.SearchQuery}
        onChange={(e) => {
          debounce(searchList(e.target.value), 300);
        }}
        showSearchBar={showSearchBar}
      />
      <img
        alt="search"
        src={require("static/images/search.svg")}
        className="search-icon"
      />
      {props.SearchQuery &&
        props.SearchQuery.length > 2 &&
        props.SearchComplete === false && (
          <img
            alt="loader"
            src={require("static/images/loader-block-user.svg")}
            className="loader fade"
          />
        )}
      <Flexbox>
        <Select
          components={{ DropdownIndicator }}
          value={
            LocalStorage.fetch.countryData() && showAllResults
              ? LocalStorage.fetch.countryData()
              : getCountry()
          }
          styles={customStyles}
          options={showAllResults ? countryOptions : props.CountryList}
          onChange={changeCountry}
        />
        <Flexbox marginLeft="12px" flexDirection="column">
          <UserDropdown
            profile="true"
            onClick={() => setOpenProfileDropdown(!openProfileDropdown)}
            ref={(node) => (ProfileNode.current = node)}
          >
            <img
              alt="profile-pic"
              className="profile-pic"
              src={require("static/images/profile-pic.jpg")}
            />
            <span className="profile-name">Priyanshi Tater</span>
            <img
              alt="dropdown"
              src={require("static/images/dropdown-arrow.svg")}
            />
          </UserDropdown>
          <UserDropdownContent open={openProfileDropdown}>
            <div className="option" onClick={logout}>
              Logout
            </div>
          </UserDropdownContent>
        </Flexbox>
      </Flexbox>
    </SearchContainer>
  );
}
export default withRouter(
  connect(
    ({ Country, Category, Search }) => ({
      ...Country,
      ...Category,
      ...Search
    }),
    (dispatch) => bindActionCreators({ ...searchActions }, dispatch)
  )(Header)
);
