import styled from "styled-components";
import { Theme, Size, FontFamily } from "static/coreStyles/theme.styled";

export const CategoryRowContainer = styled.div`
  border-radius: 6px;
  background-color: ${Theme.white};
  padding: 22px 20px;
  margin-bottom: 8px;
  :first-child {
    margin-top: 12px;
  }
  :last-child {
    margin-bottom: 0;
  }

  .top-apps {
    margin: 0 0 0 9px;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.sm};
    font-weight: 500;
    line-height: 1.07;
    color: #636970;
  }
  .bar {
    width: 2px;
    height: 13px;
    border-radius: 1px;
    background-color: ${(props) => props.color};
  }
  .application {
    object-fit: contain;
    margin-right: 9px;
    height: 33px;
    border-radius: 6px;
    width: 33px;
    :last-child {
      margin-right: 0;
    }
  }
`;
