import React, { useState } from "react";
import { Flexbox } from "static/coreStyles/container.styled";
import {
  UploadImageContainer,
  UploadedImageContainer,
  UploadImagePlaceholder
} from "./ImageInputContainer.styled";
import { UploadTitle, ErrorMessage } from "static/coreStyles/global.styled";

export default function ImageInputContainer(props) {
  const [image, setImage] = useState(props.img ? props.img : "");
  function clearImage() {
    setImage(null);
    props.getImage(null);
  }
  function UploadImage(e) {
    props.isChanged && props.isChanged();
    props.getImage && props.getImage(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <Flexbox flexDirection="column" marginBottom="20px">
      <Flexbox>
        <UploadTitle>{props.title}</UploadTitle>
        {props.invalid && <ErrorMessage>Required*</ErrorMessage>}
      </Flexbox>
      <UploadImageContainer bg={image ? image : false} invalid={props.invalid}>
        <input
          type="file"
          accept="image/*"
          onChange={UploadImage}
          className="custom-file-upload"
        />

        <UploadedImageContainer show={image}>
          <img alt="icon" src={image} className="uploaded-img" />
          <img
            alt="cross"
            src={require("static/images/cross.svg")}
            className="cross pointer"
            onClick={() => {
              clearImage();
            }}
          />
        </UploadedImageContainer>

        <UploadImagePlaceholder src={props.imgUrl} alt="upload" show={image} />
      </UploadImageContainer>
    </Flexbox>
  );
}
