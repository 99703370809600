import styled from "styled-components";
import { FontFamily, Size } from "static/coreStyles/theme.styled";

export const Empty = styled.div`
  margin-top: 38px;
  font-family: ${FontFamily.SemiBold};
  font-size: ${Size.base};
  font-weight: 600;
  color: #212121;
`;
