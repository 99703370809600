import { Api } from "./config/request";

export const getSection = (country) => {
  return Api.getRequest(
    `app-sections/${
      country === "Global" || country === "All" ? `` : `?countryCode=${country}`
    }`
  );
};

export const updateSection = (payload) => {
  return Api.putRequest(`app-sections/`, payload);
};

export const Section = {
  getSection,
  updateSection
};
