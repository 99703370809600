import styled from "styled-components";
import { Size, FontFamily } from "static/coreStyles/theme.styled";

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 220px;
  text-align: center;
  .empty-state-img {
    margin-bottom: 22px;
  }
  .heading {
    opacity: 0.8;
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.base};
    font-weight: 600;
    text-align: center;
    color: #212121;
    margin-bottom: 5px;
  }
  .subheading {
    opacity: 0.8;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.xs};
    font-weight: 500;
    letter-spacing: -0.05px;
    text-align: center;
    color: #222222;
  }
  @media (min-width: 1025px) {
    width: 300px;
    .empty-state-img {
      height: 120px;
      width: 120px;
    }
    .heading {
      font-size: ${Size.bl};
    }
    .subheading {
      font-size: ${Size.base};
    }
  }
`;
