const LS_KEY = {
  auth_token: "auth_token",
  user_data: "user_data",
  country: "country",
  persist_store: "persist:root"
};

const set = {
  authToken: data => {
    localStorage.setItem(
      LS_KEY.auth_token,
      JSON.stringify({
        auth_token: data
      })
    );
  },
  userData: data => {
    localStorage.setItem(
      LS_KEY.user_data,
      JSON.stringify({
        user_data: data
      })
    );
  },
  countryData: data => {
    sessionStorage.setItem(
      LS_KEY.country,
      JSON.stringify({
        country: data
      })
    );
  }
};

const fetch = {
  authToken: () => {
    const data = localStorage.getItem(LS_KEY.auth_token);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.auth_token;
      } catch (err) {
        return null;
      }
    }
  },
  userData: () => {
    const data = localStorage.getItem(LS_KEY.user_data);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.user_data;
      } catch (err) {
        return null;
      }
    }
  },
  countryData: () => {
    const data = sessionStorage.getItem(LS_KEY.country);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.country;
      } catch (err) {
        return null;
      }
    }
  }
};

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY.auth_token);
  },
  userData: () => {
    localStorage.removeItem(LS_KEY.user_data);
  },
  countryData: () => {
    sessionStorage.removeItem(LS_KEY.country);
  },
  persistStore: () => {
    localStorage.removeItem(LS_KEY.persist_store);
  }
};

export const storage = {
  set,
  fetch,
  destroy
};
