import styled from "styled-components";
import { Theme, Size, FontFamily } from "static/coreStyles/theme.styled";

export const ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-end"};
  .pool-toggle {
    font-family: ${Theme.SemiBold};
    font-size: ${Size.sm};
    font-weight: 600;
    text-align: right;
    color: ${Theme.primaryColor};
    margin-right: 7px;
  }
`;

export const ExploreRowContainer = styled.div`
  border-radius: 6px;
  background-color: ${Theme.white};
  padding: 22px 20px;
  margin-bottom: 14px;

  :first-child {
    margin-top: 14px;
  }

  :nth-child(3) {
    border-bottom: 2px solid #f7f8f8;
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  :nth-child(4) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  :last-child {
    margin-bottom: 0;
  }

  .pointer {
    cursor: pointer;
  }

  .reorder {
    display: flex;
    flex-wrap: wrap;
  }
  .top-apps {
    margin: 0 0 0 9px;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.sm};
    font-weight: 500;
    line-height: 1.07;
    color: #636970;
  }
  .bar {
    width: 2px;
    height: 13px;
    border-radius: 1px;
    background-color: #d52a56;
  }
  .application {
    height: 38px;
    width: 38px;
    border-radius: 6px;
  }
  .app-name {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.xs};
    font-weight: 500;
    color: #636970;
    margin-top: 9px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
  .app-edge {
    color: ${Theme.primaryColor};
  }
  .app-icon-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-right: 15px;
    padding: 10px 0;
    margin-bottom: 10px;
    :last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
    .edit-section {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -13px;
      right: -10px;
      display: none;
    }

    :hover {
      cursor: ${(props) =>
        props.banner || props.reorderable ? "pointer" : "unset"};
      box-shadow: ${(props) =>
        props.banner || props.reorderable
          ? "0 2px 12px 0 rgba(0, 0, 0, 0.07)"
          : "unset"};
      .edit-section {
        display: block;
      }
    }
  }

  .add-new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background-color: rgba(160, 181, 205, 0.2);
    border-radius: 8px;
  }
  .add-new-circle {
    width: 13px;
    height: 13px;
  }
  .view-all {
    width: 16px;
    height: 10px;
    font-family: ${FontFamily.Medium};
    font-size: 14px;
    font-weight: 500;
    line-height: 0.71;
    text-align: center;
    color: #636970;
  }
  .ad-icon {
    position: absolute;
    top: -8px;
    left: 25px;
    width: 22px;
    height: 17px;
    border-radius: 6px;
    padding-top: 2px;
    border: solid 0.9px ${Theme.white};
    background-color: #52caf7;
    font-family: ${FontFamily.SemiBold};
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.03px;
    text-align: center;
    color: #08416f;
  }
`;
