import React, { useState, useEffect } from "react";
import { SectionsRowContainer, AppContainer } from "./SectionsRow.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import { ItemContainer } from "components/ExploreRow/ExploreRow.styled";
import SectionDrawer from "components/DrawerModules/SectionDrawer";
import AppGroup from "components/common/AppGroup";
import { ApplicationSubContainer } from "views/CategoryDetail/CategoryDetail.styled";
import { connect } from "react-redux";
import AdPool from "components/AdPool";
import { ReactSortable } from "react-sortablejs";
import { CommonButton } from "static/coreStyles/global.styled";
import { getScope } from "utility/helperFunctions";
import swal from "sweetalert";
import * as sectionActions from "store/Section/actions";
import { bindActionCreators } from "redux";

function SectionsRow(props) {
  const [showSectionDrawer, setShowSectionDrawer] = useState(false);
  const [edit, setEdit] = useState(false);
  const [index, setIndex] = useState(null);
  const [type, setType] = useState("application");
  const [defaultValue, setDefaultValue] = useState(undefined);
  const [data, setData] = useState(props.data);
  const [reordered, setReordered] = useState(false);
  const [loader, setLoader] = useState(false);

  let advertisements = props.data
    ? props.data.filter((obj) => {
        return obj.type === 2;
      })
    : [];
  let apps = props.data
    ? props.data.filter((obj) => {
        return obj.type === 1;
      })
    : [];

  function saveList() {
    setLoader(true);
    let list = data.map((val) => {
      let item = {
        ids: val.ids,
        type: val.type
      };
      if (val.name) {
        item.name = val.name;
      }
      return item;
    });
    let payload = {
      type:
        props.sectionKey === "dashboard"
          ? 3
          : props.sectionKey === "appsWeLove"
          ? 1
          : 2,
      scope: getScope(true),
      items: list
    };

    props.updateSectionRequest(payload).then((res) => {
      if (res.error) {
        setLoader(false);
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        props.getSectionRequest();
        setReordered(false);
        setLoader(false);
      }
    });
  }
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function EditApp(type, apps, name, index) {
    setEdit(true);
    setIndex(index);
    setType(type);
    let defaultVal =
      !apps || apps === []
        ? undefined
        : type === "application"
        ? {
            label: props.Section.apps[apps].name,
            value: props.Section.apps[apps].id
          }
        : type === "group"
        ? apps.map((app) => {
            return {
              label: props.Section.apps[app].name,
              value: props.Section.apps[app].id
            };
          })
        : apps.map((app) => {
            return {
              label: props.Section.advs[app].app.name,
              value: props.Section.advs[app].id
            };
          });
    if (name) defaultVal.name = name;
    setDefaultValue(defaultVal);
    setShowSectionDrawer(true);
  }

  return (
    <div>
      <SectionsRowContainer>
        <Flexbox>
          <ItemContainer justifyContent="flex-start">
            <TitleContainer>
              <div>
                <div>{props.title}</div>
                <div className="subtext">{`${apps.length} Application${
                  apps.length > 0 ? `s` : ""
                } • ${advertisements.length} Advertisement${
                  advertisements.length > 0 ? `s` : ""
                }`}</div>
              </div>
            </TitleContainer>
          </ItemContainer>
          {reordered && (
            <CommonButton
              marginRight="20px"
              height="35px"
              onClick={saveList}
              width="95px"
              app
            >
              {loader ? (
                <img
                  alt="loader"
                  src={require("static/images/loader-button.svg")}
                  className="loader"
                />
              ) : (
                "Save"
              )}
            </CommonButton>
          )}
        </Flexbox>

        <ApplicationSubContainer margin="20px 0 0" section>
          <Flexbox marginTop="32px">
            <Flexbox
              className="pointer"
              flexDirection="column"
              alignItems="center"
              margin="10px 20px 10px 0"
              onClick={() => {
                setShowSectionDrawer(true);
                setEdit(false);
                setType("application");
              }}
            >
              <div className="add-new">
                <img
                  alt="plus"
                  src={require("static/images/add-circle.svg")}
                  className="add-new-circle"
                />
              </div>
              <div className="app-name app-edge">Add New</div>
            </Flexbox>
            <ReactSortable
              list={data}
              setList={setData}
              onChange={() => setReordered(true)}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              className="reorder"
            >
              {data && data.length > 0
                ? data.map((item, index) => {
                    let type = item.name
                      ? "group"
                      : item.type === 2
                      ? "pool"
                      : "application";
                    let apps =
                      item.ids && item.ids.length > 0
                        ? type === "application"
                          ? item.ids[0]
                          : item.ids
                        : type === "application"
                        ? ""
                        : [];

                    return (
                      <AppContainer key={index}>
                        <img
                          alt="edit"
                          className="edit-section"
                          src={require("static/images/edit-section.svg")}
                          onClick={() =>
                            EditApp(
                              type,
                              apps,
                              type === "group" && item.name,
                              index
                            )
                          }
                        />

                        {apps && type === "application" ? (
                          <Flexbox flexDirection="column" alignItems="center">
                            <img
                              alt="app"
                              src={
                                props.Section.apps[apps] &&
                                props.Section.apps[apps].icon &&
                                props.Section.apps[apps].icon.mediaUrl
                              }
                              className="application"
                            />
                            <div className="app-name">
                              {props.Section.apps[apps] &&
                                props.Section.apps[apps].name}
                            </div>
                          </Flexbox>
                        ) : apps && apps.length ? (
                          type === "pool" ? (
                            <AdPool ids={apps} />
                          ) : (
                            <AppGroup ids={apps} name={item.name} />
                          )
                        ) : null}
                      </AppContainer>
                    );
                  })
                : null}
            </ReactSortable>
          </Flexbox>
        </ApplicationSubContainer>
      </SectionsRowContainer>
      {showSectionDrawer && (
        <SectionDrawer
          edit={edit}
          type={type}
          data={data}
          defaultValue={edit ? defaultValue : undefined}
          sectionKey={props.sectionKey}
          show={showSectionDrawer}
          closeDrawer={() => {
            setShowSectionDrawer(false);
          }}
          index={index}
        />
      )}
    </div>
  );
}

export default connect(
  ({ Section }) => ({ ...Section }),
  (dispatch) => bindActionCreators({ ...sectionActions }, dispatch)
)(SectionsRow);
