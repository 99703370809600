import { Login } from "services/Login";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";

const handleError = err => {
  console.log("Error in Login action : ", err);
};

// Actions
export const adminLoginRequest = payload => async dispatch => {
  try {
    let data = await Login.adminLogin(payload);
    if (data.result) {
      dispatch(mutation.setAdminDetails(data.result));
      LocalStorage.set.authToken(data.result.authToken);
    }
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};
