import { Api } from "./config/request";

export const getCategoryList = (country, page) => {
  return Api.getRequest(
    `${
      page
        ? page
        : `categories/?pageSize=1000${
            country === "Global" || country === "All"
              ? ``
              : `&&countryCode=${country}`
          }`
    }`
  );
};
export const createCategory = (payload) => {
  return Api.postRequest(`categories/`, payload);
};
export const updateCategoryById = (id, payload) => {
  return Api.putRequest(`categories/${id}`, payload);
};
export const getCategoryDetail = (id, country) => {
  return Api.getRequest(
    `categories/${id}/sections${
      country === "Global" || country === "All" ? `` : `?countryCode=${country}`
    }`
  );
};
export const updateCategoryDetail = (id, payload) => {
  return Api.putRequest(`categories/${id}/sections`, payload);
};

export const changeCategoryActiveStatus = (id, active) => {
  return Api.postRequest(
    `categories/${id}/${active ? "activate" : "deactivate"}`
  );
};
export const Category = {
  getCategoryList,
  createCategory,
  updateCategoryById,
  getCategoryDetail,
  updateCategoryDetail,
  changeCategoryActiveStatus
};
