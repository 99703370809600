import styled from "styled-components";
import { FontFamily, Theme, Size } from "static/coreStyles/theme.styled";

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: ${(props) => props.width};
  .dropdown__control {
    border: solid 1px
      ${(props) =>
        props.invalid
          ? "rgba(255, 0, 0, 0.60)"
          : "rgba(26, 65, 106, 0.12)"} !important;
  }
`;

export const customStyles = {
  control: (base) => ({
    ...base,
    width: "100%",
    minHeight: "50px",
    marginTop: "12px",
    borderRadius: "6px",
    border: "solid 1px rgba(26, 65, 106, 0.12)",
    padding: "0 10px",
    boxShadow: "none",
    "&:hover": {
      cursor: "text",
      border: "solid 1px rgba(26, 65, 106, 0.12)"
    }
  }),
  placeholder: (base) => ({
    ...base,
    opacity: "0.2",
    fontFamily: FontFamily.Medium,
    fontSize: Size.base,
    fontWeight: "500",
    color: "#212121"
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none"
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    cursor: "pointer",
    display: state.isDisabled ? "none" : "flex"
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "246px"
  }),
  option: (base, state) => ({
    ...base,
    cursor: "pointer",
    fontFamily: FontFamily.Medium,
    fontSize: Size.base,
    fontWeight: "600",
    color: "#212121",
    backgroundColor: Theme.white,
    "&:hover": {
      color: Theme.primaryColor
    },
    ":active": {
      ...base[":active"],
      backgroundColor: Theme.white
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontFamily: FontFamily.SemiBold,
    fontSize: Size.base,
    fontWeight: "600",
    color: "#212121"
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0"
  }),
  multiValue: (base, state) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    backgroundColor: "rgba(11, 95, 227, 0.1)",
    padding: "4px 4px",
    paddingRight: state.isDisabled ? "10px" : "4px",
    cursor: "pointer"
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#005bec",
    fontFamily: FontFamily.SemiBold,
    fontSize: Size.sm,
    fontWeight: "600",
    padding: "0"
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#0056f5",
    color: Theme.white,
    margin: "0 5px",
    padding: "0",
    display: state.isDisabled ? "none" : "flex",
    "&:hover": {
      backgroundColor: "#0056f5",
      color: Theme.white
    }
  })
};
