import React, { useState } from "react";
import { AppFlex } from "./SubcategoryRow.styled";
import { ButtonFilter } from "static/coreStyles/global.styled";
import { ApplicationSubContainer } from "views/CategoryDetail/CategoryDetail.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import { storage as LocalStorage } from "services/config/storage";

function SubcategoryRow(props) {
  const [countryFilter, setCountryFilter] = useState("All");
  const category = props.category;
  const detail = props.detail;

  function changeCountryFilter(country) {
    country === countryFilter
      ? setCountryFilter("All")
      : setCountryFilter(country);
  }

  return (
    <ApplicationSubContainer>
      <Flexbox justifyContent="space-between">
        <Flexbox>
          <h4>
            {category.name}
            {category.apps && category.apps.length
              ? ` (` + category.apps.length + `)`
              : ""}
          </h4>
          {LocalStorage.fetch.countryData() &&
            LocalStorage.fetch.countryData().label !== "Global" && (
              <>
                <ButtonFilter
                  className="pointer"
                  toggle={countryFilter === true}
                  onClick={() => changeCountryFilter(true)}
                >
                  Global
                </ButtonFilter>
                <ButtonFilter
                  className="pointer"
                  toggle={countryFilter === false}
                  onClick={() => changeCountryFilter(false)}
                >
                  {LocalStorage.fetch.countryData().label}
                </ButtonFilter>
              </>
            )}
        </Flexbox>
        {category && category.apps && category.apps.length > 0 && (
          <Flexbox justifyContent="flex-end" marginBottom="20px">
            <img
              src={require("static/images/edit.svg")}
              alt="edit"
              className="pointer"
              onClick={() => props.showCategoryAppsDrawer([true, category])}
            />
          </Flexbox>
        )}
      </Flexbox>
      <Flexbox overflowX="auto" paddingTop="8px">
        {category && category.apps && !category.apps.length && (
          <Flexbox
            flexDirection="column"
            alignItems="center"
            margin="0 30px 20px 0"
          >
            <div
              className="add-new pointer"
              onClick={() => props.showCategoryAppsDrawer([true, category])}
            >
              <img
                alt="plus"
                src={require("static/images/add-circle.svg")}
                className="add-new-circle"
              />
            </div>
            <div className="app-name app-edge">Add New</div>
          </Flexbox>
        )}
        {category.apps &&
          category.apps
            .filter(
              (app) =>
                detail.allApps[app].scope.isGlobal === countryFilter ||
                countryFilter === "All"
            )
            .map((app, index) => {
              const id = app;
              app = detail.allApps[app];
              return (
                <AppFlex key={index}>
                  {props.loader ? (
                    <div className="loader-container">
                      <img
                        alt="loader"
                        src={require("static/images/loader-button.svg")}
                        className="loader fade"
                      />
                    </div>
                  ) : (
                    <img
                      alt="hide"
                      src={require("static/images/visible.svg")}
                      className="visible pointer"
                      onClick={() => props.removeApp(id)}
                    />
                  )}
                  <img
                    alt={app.name}
                    src={app.icon && app.icon.mediaUrl}
                    className="app-img"
                  />
                  <div className="app-name">{app.name}</div>
                </AppFlex>
              );
            })}
      </Flexbox>
    </ApplicationSubContainer>
  );
}
export default SubcategoryRow;
