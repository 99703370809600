import { initialState } from "./state";

export const Application = (state = initialState, action) => {
  switch (action.type) {
    case "SET_APPLICATION_LIST": {
      return Object.assign({}, state, {
        ApplicationList: action.data
      });
    }
    case "SET_APPLICATION_PAGE": {
      return Object.assign({}, state, {
        ApplicationPage: action.data
      });
    }
    default:
      return state;
  }
};
