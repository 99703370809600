import { Api } from "./config/request";

export const getAdvertisementList = (country, page) => {
  return Api.getRequest(
    `${
      page
        ? page
        : `advertisements/${
            country === "Global" || country === "All"
              ? ``
              : `?countryCode=${country}`
          }`
    }`
  );
};

export const createAdvertisement = (payload) => {
  return Api.postRequest(`advertisements/`, payload);
};

export const updateAdvertisementById = (id, payload) => {
  return Api.putRequest(`advertisements/${id}`, payload);
};

export const changeAdvertisementsActiveStatus = (id, active) => {
  return Api.postRequest(
    `advertisements/${id}/${active ? "activate" : "deactivate"}`
  );
};

export const deleteAdvertisementById = (id) => {
  return Api.deleteRequest(`advertisements/${id}`);
};
export const Advertisement = {
  getAdvertisementList,
  createAdvertisement,
  updateAdvertisementById,
  changeAdvertisementsActiveStatus,
  deleteAdvertisementById
};
